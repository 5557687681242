import React from "react";
import "./Feature.css";

interface Props {
  number: string;
  image: string;
  title: string;
  description: string;
}

const Feature = ({ number, image, title, description }: Props) => {
  return (
    <div className="feature sm:w-3/5 min-h-20 ">
      <div className=" flex justify-between gap-x-10">
        <div className="relative pt-4">
          <p className="big-number font-extrabold absolute p-0 m-0 -top-1 -left-11 sm:-left-16 z-10 w-full h-full text-6xl sm:text-7xl">
            {number}
          </p>
          <div className="flex justify-center bg-white w-20 h-20 sm:w-28 sm:h-28 rounded-xl">
            <img src={image} alt="" className="icon-image w-1/2" />
          </div>
        </div>
        <div>
          <h3 className="title font-bold pb-2 sansationFont text-left">
            {title}
          </h3>
          <p className="description avenirFont text-left">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Feature;
