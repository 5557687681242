import { getAccessToken } from "../auth/auth";
import { initFetchRequest, initPostRequest } from "../utils/helperFunctions";
import { fetchData } from "../utils/helperFunctions";
// This function is used to get specific data for graph display and filters data on the frontend
export const getGraphData = async (
  graphType,
  gender,
  startAge,
  endAge,
  startDate,
  endDate
) => {
  startDate = startDate.toISOString().split("T")[0];
  endDate = endDate.toISOString().split("T")[0];
  graphType = graphType.toLowerCase();
  var graphEndpoint = `/api/patients/filter?startDate=${startDate}&endDate=${endDate}&youngestAge=${startAge}&oldestAge=${endAge}&gender=${gender}&graphType=${graphType}`;
  var token = await getAccessToken();
  var myRequest = initFetchRequest(graphEndpoint, token);
  var data = await fetchData(myRequest);
  return data;
};

// TODO: MOve to patient calls folder
export const getTableData = async (
  graphType,
  startDate,
  endDate,
  patientID
) => {
  startDate = startDate.toISOString();
  endDate = endDate.toISOString();
  graphType = graphType.toLowerCase();
  var graphEndpoint;
  if (graphType === "micronutrients") {
    graphEndpoint = `/api/patients/DailyNutrientTotals`;
  } else {
    graphEndpoint = `/api/patients/DailyMacroNutrientTotals`;
  }
  var token = await getAccessToken();
  //console.log(startDate)

  var body = {
    period: 5,
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    organizationIDs: null,
    doctorIDs: null,
    studyIDs: null,
    userIDs: [patientID],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  return data;
};

export const getExportData = async (startDate, endDate, patientID) => {
  const graphEndpoint = `/api/patients/DailyNutrientTotalsV2`;
  const token = await getAccessToken();

  var body = {
    period: 5,
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    userIDs: [patientID],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  return data;
};

export const getStudyData = async (startDate, endDate, userID, studyID) => {
  console.log(userID, studyID);
  const graphEndpoint = `/api/Study/AverageDailyNutrientConsumptionForStudy`;
  const token = await getAccessToken();

  var body = {
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    userIDs: [userID],
    studyIDs: [studyID],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  data.$values = data.$values.map((item) => {
    const newItem = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        let newKey = key.startsWith("avg") ? key.replace(/^avg/, "") : key;
        newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        newItem[newKey] = item[key];
      }
    }
    return newItem;
  });
  return data;
};
