import { ReactComponent as PatientIcon } from "../../../../icons/PatientsIconFig.svg";
import { ReactComponent as MobileIcon } from "../../../../icons/MobileIconFig.svg";
import { ReactComponent as MealIcon } from "../../../../icons/MealsIconFig.svg";
import { ReactComponent as SymptomIcon } from "../../../../icons/SymptomsIconFig.svg";
import "../../../../overall.css";

function StudyInformationCard(props: any) {
  return (
    <div className="si-mainContainer box-container">
      <div className="row edit-row ">
        <div className="card-title-text">STUDY OVERVIEW</div>
        <div className="edit-button">
          <button className="text-button">Edit</button>
        </div>
      </div>

      <div className="row mt-2">
        <PatientIcon className="sub-title-icon" />
        <div className="title-size stats-title icon-text-stats text-gray-600 ">
          {props.studies[props.studyIndex]?.patients.$values.length}
        </div>
        <div className="body-text-size body-text icon-text-text">
          Patients Enrolled
        </div>
      </div>
      <div className="row mt-2">
        <MobileIcon className="sub-title-icon" />
        <div className="title-size stats-title icon-text-stats text-gray-600">
          {parseInt(
            props.studyInfo[props.studies[props.studyIndex]?.studyID]
              ?.totalMealsLogged
          ) ||
            0 +
              parseInt(
                props.studyInfo[props.studies[props.studyIndex]?.studyID]
                  ?.totalSymptomsLogged
              ) ||
            0}
        </div>
        <div className="body-text-size body-text icon-text-text">
          Mobile Logging
        </div>
      </div>
      <div className="row mt-2">
        <MealIcon className="sub-title-icon" />
        <div className="title-size stats-title icon-text-stats text-gray-600">
          {parseInt(
            props.studyInfo[props.studies[props.studyIndex]?.studyID]
              ?.totalMealsLogged
          ) || 0}
        </div>
        <div className="body-text-size body-text icon-text-text">
          Total Meals Logged
        </div>
      </div>
      <div className="row mt-2">
        <SymptomIcon className="sub-title-icon" />
        <div className="title-size stats-title icon-text-stats text-gray-600">
          {parseInt(
            props.studyInfo[props.studies[props.studyIndex]?.studyID]
              ?.totalSymptomsLogged
          ) || 0}
        </div>
        <div className="body-text-size body-text icon-text-text">
          Total Symptoms Logged
        </div>
      </div>
    </div>
  );
}

export default StudyInformationCard;
