import React from "react";
import "./LoadingIcon.css";

function LoadingIcon() {
  return (
    <div id="loader-wrapper">
      <div id="loader"></div>
    </div>
  );
}

export default LoadingIcon;
