import React from 'react';
import './DownloadAppModal.css';

interface DownloadAppModalProps {
    show: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const DownloadAppModal: React.FC<DownloadAppModalProps> = ({ show, onClose, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={`modal ${show ? 'show' : ''}`} onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <button onClick={onClose} className="close-button">&times;</button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    );
};
