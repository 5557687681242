import React, { useEffect } from "react";
// import { useState,  } from 'react';
import PatientInformationCard from "./components/IndividualProfile/PatientInformationCard";
import IndividualPatientOverviewCard from "./components/IndividualProfile/IndividualPatientOverviewCard";
import PatientGraphComponent from "./components/IndividualProfile/ProfileGraphComponent";
import TitleBar from "../../components/common/TitleBar";
import "./Dashboard.css";
import "../../overall.css";
//import { getAccessToken } from '../../auth/auth'
//import LoadingIcon from '../../components/common/LoadingIcon'
//import { fetchData, initFetchRequest } from '../../utils/helperFunctions'
//import { constant, forEach } from 'lodash'

// import GraphDisplay from "./GraphDisplay"
type IndividualPatientProps = {
  patients: AzureUserInfo[];
};
type AzureUserInfo = {
  displayName: string;
  givenName: string;
  jobTitle?: string | null;
  mail?: string | null;
  mobilePhone?: string | null;
  officeLocation?: string | null;
  preferredLanguage?: string | null;
  surname: string;
  userPrincipalName: string;
  patientID: string;
  id: string;
  BusinessPhones?: string[] | null;
};
/*interface LocationState {
    patientAuthID: string;
    patientID: string;
    firstName: string;
    lastName: string;
    username: string;
  }*/

function IndividualPatient(patientData: IndividualPatientProps) {
  // const [data, setData] = useState<AzureUserInfo>();
  //const [firstName, setFirstName] = useState<string>( );
  //const [surname, setSurname] = useState<string>();
  let surname = "";
  let firstName = "";
  let username = "";
  console.log(username);
  // const [username, setUsername] = useState<string>( );
  // const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  var currentPatient = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  patientData.patients.forEach((item) => {
    console.log(item);
    // console.log("logging item" + item.givenName + item.surname)
    //console.log(item.patientID);

    //string patientId
    if (parseInt(item.patientID) === parseInt(currentPatient)) {
      //console.log("Assigning");
      //setFirstName(item.givenName);
      firstName = item.givenName;
      //console.log("printin item.surname " + item.surname);
      surname = item.surname;
      //console.log("printin surname " +surname);
      // setUsername(item.displayName);
      username = item.displayName;
      // console.log("Assigned");
    }

    //  console.log("logging assigned "+ firstName+" surnmae: "+surname);
  });

  // Set up the states for visualization readd variables to state (had to supress warnings for deployment)
  useEffect(() => {
    //getUserData()
  }, [patientData]);

  /*const getUserData = async () => {
        setDataLoaded(false);
        var token = await getAccessToken();
        const request = initFetchRequest(
            "/api/Users/GetUserAttributes/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
            token
        );
        
        const response = await fetchData(request);
        console.log(response)
        setData(response)
        setDataLoaded(true);
    }*/

  return (
    <div className="parentContainer">
      <div className="mainContainer studyDashboard">
        {" "}
        {/* Rule in app.css */}
        {/*dataLoaded === false
                    ? (<div className="small-loadingContainer">
                        <LoadingIcon />
                    </div>
                    )
                    :
                    (
                        <>
                            <TitleBar currentPage={[false, false, false, true]} firstName={firstName} lastName={surname} userName={username} ></TitleBar>
                        </>
                    )
                */}
        <>
          <TitleBar
            title={"Patient profile"}
            subtitle={`${firstName} ${surname}`}
          />
        </>
        <div className="cardParentContainer">
          <div className="studyInformation row">
            <PatientInformationCard patientID={currentPatient} />
            <IndividualPatientOverviewCard patientID={currentPatient} />
          </div>
          <PatientGraphComponent
          // graphToDisplay={visualization}
          // macroChecks={macroChecks}
          />
        </div>
      </div>
    </div>
  );
}

export default IndividualPatient;
