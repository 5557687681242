import React, { Component, ChangeEvent } from "react";
import VisualizationDropDown from "./components/VisualizationDropDown";
import GenderDropDown from "./components/GenderDropDown";
// import VitaminDropDown from './components/VitaminDropDown';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { getSignedInUserId } from "../../auth/auth";
import "../../components/navigation/NavBar.css";
import "../../overall.css";
//import { theme } from "../../utils/materialUI";

import { FetchInfo } from "../../components/common/types/FetchInfo";
import { MacroChecks } from "../../components/common/types/MacroChecks";
import VitaminGraph from "./components/Graphs/VitaminGraph";
import MineralGraph from "./components/Graphs/MineralGraph";
import LoggingGraph from "./components/Graphs/LoggingGraph";
import MacroGraph from "./components/Graphs/MacroGraph";
import SeverityGraph from "./components/Graphs/SeverityGraph";
// import {
//   //  FormControl,
//   // MenuItem,
//   // Select,
//   // SelectChangeEvent,
//   //Theme,
// } from "@mui/material";
import GraphTypeDropDown from "./components/GraphTypeDropDown";
import MacroCheckBox from "./components/MacroCheckBox";
import { FaDownload } from "react-icons/fa";

// import MacroCheckBox from "./components/MacroCheckBox";

//import { styled } from "@mui/system";

// const apiEndpoints: { [key: string]: string } = {
//   VitaminGraph: "/api/Graphs/VitaminData",
//   MineralGraph: "/api/Graphs/MineralData",
//   LoggingGraph: "/api/Graphs/LoggingData",
//   MacroGraph: "/api/Graphs/MacroData",
//   SeverityGraph: "/api/Graphs/SeverityData",
// };

interface GraphDisplayProps {
  isStudyDash: boolean;
  api_data: any[]; // Add this line
  macroChecks: any;
  setData: (data: any) => void;
  setFetchInfo: (info: FetchInfo) => void;
  setMacroChecks: (checks: MacroChecks) => void;
  setVisualization: (visualization: string) => void;
  handleDownloadData: () => void;
  handleMacroChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleLoadData: (userId: string) => void;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  handleGraphTypeChange: (value: string) => void;
  handleVisualizationChange: (value: string) => void;
  graphType: string;
  visualization: string;
  startDate: Date;
  endDate: Date;
}

interface GraphDisplayState {
  visualization: string;
  graphType: string;
  vitamin: string;
  macro: string;
  fatsChecked: boolean;
  carbsChecked: boolean;
  proteinsChecked: boolean;
  caloriesChecked: boolean;
  gender: string;
  minAge: number;
  maxAge: number;
  selectedDateOne: Date;
  selectedDateTwo: Date;
  isGraphDataLoading: boolean;
  isGraphButtonClicked: boolean;
  api_data: any | null;
  vitaminData: any | null;
  mineralData: any | null;
  loggingData: any | null;
  macroData: any | null;
  severityData: any | null;
  isStudyDash: boolean;
}

//const FormControlStyled = styled(FormControl)(({ theme }) => ({
//  margin: theme.spacing(1),
//}));

/*const BootstrapSelect = styled(Select)(({ theme }: { theme: Theme }) => ({
  "& .MuiSelect-select": {
    "position": "relative",
    "backgroundColor": "#FAFAFA",
    "color": "#5FBDD6",
    "border": "1px solid #5FBDD6",
    "fontSize": 16,
    "fontWeight": "bold",
    "transition": theme.transitions.create(["border-color", "box-shadow"]),
    "fontFamily": [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 6,
      borderColor: "#5FBDD6",
    },
  },
}));*/

class GraphDisplay extends Component<GraphDisplayProps, GraphDisplayState> {
  constructor(props: GraphDisplayProps) {
    super(props);

    this.state = {
      visualization: "macronutrients",
      graphType: "table",
      vitamin: "",
      macro: "",
      fatsChecked: true,
      carbsChecked: true,
      proteinsChecked: true,
      caloriesChecked: true,
      gender: "",
      minAge: 0,
      maxAge: 120,
      selectedDateOne: new Date(),
      selectedDateTwo: new Date(),
      isGraphDataLoading: false,
      isGraphButtonClicked: false,
      api_data: null,
      vitaminData: null,
      mineralData: null,
      loggingData: null,
      macroData: null,
      severityData: null,
      isStudyDash: props.isStudyDash,
    };
  }

  componentDidMount() {
    //console.log(this.props.isStudyDash);
  }

  componentDidUpdate() {
    // console.log(this.props.isStudyDash);
  }

  componentWillUnmount() {}

  onChangeVisualization = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "micronutrients") {
      this.props.handleGraphTypeChange("table");
    }
    this.props.handleVisualizationChange(e.target.value);
  };

  onChangeGraphType = (e: ChangeEvent<HTMLSelectElement>) => {
    // if (e.target.value === "table" || this.state.visualization === "calories") {
    //   this.props.handleGraphTypeChange(e.target.value);
    //   this.props.handleVisualizationChange("macronutrients");
    // } else {
    this.props.handleGraphTypeChange(e.target.value);
    // }
  };

  onChangeVitamin = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ vitamin: e.target.value });
  };

  onChangeMacro = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.checked } as any, () => {
      this.props.setMacroChecks({
        fatsChecked: this.state.fatsChecked,
        carbsChecked: this.state.carbsChecked,
        proteinsChecked: this.state.proteinsChecked,
        caloriesChecked: this.state.caloriesChecked,
      });
    });
  };

  onChangeGender = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ gender: e.target.value });
  };

  onChangeAgeOne = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) } as any);
  };

  onChangeAgeTwo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) } as any);
  };

  handleDateOneChange = (date: Date | null) => {
    if (date) {
      // this.setState({ selectedDateOne: date });
      this.props.handleStartDateChange(date);
    }
  };

  handleDateTwoChange = (date: Date | null) => {
    if (date) {
      // this.setState({ selectedDateTwo: date });
      this.props.handleEndDateChange(date);
    }
  };

  getUserId = async () => {
    let userID = parseInt(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
    if (isNaN(userID)) {
      var userIDfromcall = await getSignedInUserId();
      if (userIDfromcall === null) {
        console.error("Failed to fetch user ID");
        alert("Failed to fetch the user ID. Please try again.");
        return;
      } else {
        userID = userIDfromcall;
      }
    }
    return userID;
  };

  getFechInfo = async (): Promise<FetchInfo | undefined> => {
    let userId = await this.getUserId();
    if (!userId) {
      return;
    }
    return {
      visualization: this.props.visualization,
      graphType: this.props.graphType,
      gender: this.state.gender,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedDateOne: this.props.startDate,
      selectedDateTwo: this.props.endDate,
      userID: userId.toString(),
    };
  };

  handleSubmitDataClick = async () => {
    let userID = await this.getUserId();
    if (!userID) {
      return;
    }
    this.props.handleLoadData(userID.toString());

    this.props.setData([]);
    this.setState({
      isGraphDataLoading: true,
      isGraphButtonClicked: true,
    });
    const fetchInfo = await this.getFechInfo();
    if (!fetchInfo) {
      return;
    }
    this.props.setFetchInfo(fetchInfo);

    // const apiEndpoint = apiEndpoints[this.state.graphType];

    // try {
    //   const response = await fetch(apiEndpoint, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(fetchInfo),
    //   });
    //   const data = await response.json();
    //   this.setState({ api_data: data });

    //   switch (this.state.graphType) {
    //     case "VitaminGraph":
    //       this.setState({ vitaminData: data });
    //       break;
    //     case "MineralGraph":
    //       this.setState({ mineralData: data });
    //       break;
    //     case "LoggingGraph":
    //       this.setState({ loggingData: data });
    //       break;
    //     case "MacroGraph":
    //       this.setState({ macroData: data });
    //       break;
    //     case "SeverityGraph":
    //       this.setState({ severityData: data });
    //       break;
    //     default:
    //       break;
    //   }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // } finally {
    //   this.setState({ isGraphDataLoading: false });
    // }
  };

  handleExportDataClick = () => {
    this.props.setData([]);
    this.setState({
      isGraphDataLoading: true,
      isGraphButtonClicked: true,
    });

    let userID = parseInt(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
    if (isNaN(userID)) {
      console.error("Failed to fetch user ID");
      alert("Failed to fetch the user ID. Please try again.");
      return;
    }

    const fetchInfo: FetchInfo = {
      visualization: this.state.visualization,
      graphType: this.state.graphType,
      gender: this.state.gender,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedDateOne: this.state.selectedDateOne,
      selectedDateTwo: this.state.selectedDateTwo,
      userID: userID.toString(),
      export: true,
    };
    this.props.setFetchInfo(fetchInfo);
  };

  renderDisplay() {
    // Placeholder for future content
    return null;
  }

  renderAgeGender() {
    if (this.state.isStudyDash) {
      return (
        <div>
          <label>Gender:</label>
          <GenderDropDown onChangeFunction={this.onChangeGender} />
          <label>Age Range:</label>
          <div className="row">
            <input
              className="age-input"
              onChange={this.onChangeAgeOne}
              type="number"
              id="min-age"
              name="minAge"
            />
            <p className="separate">to</p>
            <input
              className="age-input"
              onChange={this.onChangeAgeTwo}
              type="number"
              id="max-age"
              name="maxAge"
            />
          </div>
        </div>
      );
    }
    return <div></div>;
  }

  renderSelectedGraph() {
    const { graphType } = this.props;

    const { api_data, setMacroChecks } = this.props;

    switch (graphType) {
      case "VitaminGraph":
        return <VitaminGraph data={api_data} keyValues={["vitamin"]} />;
      case "MineralGraph":
        return <MineralGraph data={api_data} keyValues={["mineral"]} />;
      case "LoggingGraph":
        return <LoggingGraph data={api_data} />;
      case "MacroGraph":
        return <MacroGraph data={api_data} macroChecks={setMacroChecks} />;
      case "SeverityGraph":
        return <SeverityGraph data={api_data} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="visualization-form box-container">
        <form className="formContainer">
          <div className="sub-title-size sub-title mb-1">
            Data Visualization
          </div>
          <div className="body-text body-text-size">Data Group:</div>
          <VisualizationDropDown
            value={this.props.visualization}
            onChangeFunction={this.onChangeVisualization}
            options={["macronutrients", "micronutrients", "calories"]}
          />
          <div className="body-text body-text-size mt-1">
            Visualization Style:
          </div>
          <GraphTypeDropDown
            value={this.props.graphType}
            visualization={this.props.visualization}
            disabled={this.props.visualization === "micronutrients"}
            onChangeFunction={this.onChangeGraphType}
            options={[
              { value: "table", text: "Table" },
              { value: "line", text: "Line Chart" },
              { value: "bar", text: "Bar Chart" },
            ]}
          />
          {this.props.visualization === "macronutrients" &&
          this.props.graphType !== "table" ? (
            <>
              <div className="body-text body-text-size">Macro Type:</div>
              <MacroCheckBox
                onChangeFunction={this.onChangeMacro}
                macroChecks={this.props.macroChecks}
              />
            </>
          ) : null}
          {/* 
          <div className="form-group">
            <label htmlFor="graphType">Graph Type:</label>

            <FormControlStyled style={{minWidth: 170, width: "90%"}}>

            <BootstrapSelect defaultValue={this.state.graphType} sx={{marginLeft: -1}} id="graphType" onChange={this.onChangeGraphType} theme={theme} size='small' fullWidth>
              <MenuItem value="VitaminGraph">Vitamin Graph</MenuItem>
              <MenuItem value="MineralGraph">Mineral Graph</MenuItem>
              <MenuItem value="LoggingGraph">Logging Graph</MenuItem>
              <MenuItem value="MacroGraph">Macro Graph</MenuItem>
              <MenuItem value="SeverityGraph">Severity Graph</MenuItem>
            </BootstrapSelect>
            </FormControlStyled>
          </div> */}

          {/* Render different display based on the visualization selection */}
          {this.renderDisplay()}
          <div>
            {this.renderAgeGender()}
            <div className="body-text body-text-size mt-1 mb-1">
              Date Range:
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format="MM/dd/yyyy"
                  views={["year", "month", "day"]}
                  onChange={this.handleDateOneChange}
                  defaultValue={new Date()}
                />
                <p className="separate-date body-text body-text-size">to</p>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format="MM/dd/yyyy"
                  views={["year", "month", "day"]}
                  onChange={this.handleDateTwoChange}
                  defaultValue={new Date()}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="mt-4">
              <Box
                sx={{
                  "display": "flex",
                  "& > button": {
                    "backgroundColor": "#5fbdd6",
                    "color": "white",
                    "&:hover": {
                      color: "#5fbdd6",
                    },
                    "borderColor": "white",
                    "borderRadius": "10px",
                  },
                }}>
                <LoadingButton
                  sx={{ "width:": "50px" }}
                  onClick={this.handleSubmitDataClick}
                  loading={false}
                  variant="outlined">
                  Load Table
                </LoadingButton>
                <LoadingButton
                  // sx={{ "width:": "20%" }}
                  onClick={this.props.handleDownloadData}
                  loading={false}
                  variant="outlined">
                  <FaDownload />
                </LoadingButton>
              </Box>
            </div>
          </div>
        </form>
        <div className="graph-container">{this.renderSelectedGraph()}</div>
      </div>
    );
  }
}

export default GraphDisplay;
