import { ResponsiveLine } from '@nivo/line'

const PatientActivityGraph = ({ data }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 0, right: 0, bottom: 40, left: 0 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        curve="natural"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        colors={"#E3AD84"}
        lineWidth={3}
        enablePoints={false}
        pointSize={2}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.5}
        isInteractive={false}
        useMesh={true}
        legends={[]}
    />
)

export default PatientActivityGraph;