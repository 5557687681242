import React, { useCallback, useEffect, useState } from "react";
import { fetchData } from "../../../../API/CommonFunctions";
import { getAccessToken } from "../../../../auth/auth";
import ActivityCard from "../../../../components/common/ActivityCard";
import LoadingIcon from "../../../../components/common/LoadingIcon";
//import { ReactComponent as AddPatientIcon } from "../../../../icons/AddPatientFig.svg";
import { initFetchRequest } from "../../../../utils/helperFunctions";
import "../../Dashboard.css";
import "../../../../overall.css";

// type StudyInfoProps = {

// }

interface IndividualPatientOverviewCardProps {
  patientID: string | null;
}

// TODO:
function IndividualPatientOverviewCard({
  patientID,
}: IndividualPatientOverviewCardProps) {
  const [data, setData] = useState<any>();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  //Getting Stored Auth ID & Patient ID
  //const { authID, userID } = React.useContext(AuthContext);

  // Memoize the getProfileOverview function
  const getProfileOverview = useCallback(async () => {
    setDataLoaded(false);
    var token = await getAccessToken();
    const apiEndpoint =
      patientID !== null
        ? "/api/Patients/ProfileOverview/" + patientID
        : "/api/Patients/UserProfileOverview";
    //What was workin before in the individual user view
    //const apiEndpoint = userId !== "" ? "/api/Patients/ProfileOverview/" + userId : "/api/Patients/UserProfileOverview";

    //console.log("From Patient Overview Card: " + apiEndpoint);
    const request = initFetchRequest(apiEndpoint, token);
    const response = await fetchData(request);
    setData(response);
    setDataLoaded(true);
  }, [patientID]);
  useEffect(() => {
    getProfileOverview();
  }, [getProfileOverview]);

  return (
    <div className="po-mainContainer box-container">
      {dataLoaded === false ? (
        <div className="small-loadingContainer">
          <LoadingIcon />
        </div>
      ) : (
        <>
          <div className="row edit-row">
            <div className="sub-title-size sub-title mrb-12">
              Patient Overview
            </div>
            <div className="edit-button">
              {/* <button className="text-button mrb-12">Add a patient</button> */}
            </div>
          </div>
          <div className="body-text body-text-size mrb-12">This Week</div>

          <div className="stats-container row">
            <ActivityCard
              cardType={"Meals"}
              data={data.mealsWeeklyRecentlyLogged}
              label={"Recently Logged"}></ActivityCard>
            <ActivityCard
              cardType={"Symptoms"}
              data={data.symptomsWeeklyRecentlyLogged}
              label={"Recently Logged"}></ActivityCard>
          </div>
        </>
      )}
    </div>
  );
}

export default IndividualPatientOverviewCard;
