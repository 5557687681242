import { ReactComponent as PatientsIcon } from "../../icons/PatientsIconFig.svg";
import { ReactComponent as SymptomIcon } from "../../icons/SymptomsIconFig.svg";
import { ReactComponent as MealsIcon } from "../../icons/MealsIconFig.svg";

type ActivityCardProps = {
  cardType: String;
  data: Number;
  label: String;
  short?: Boolean;
};
// TODO:
function ActivityCard({ cardType, data, label, short }: ActivityCardProps) {
  let iconToUse;
  if (cardType === "Patients") {
    iconToUse = <PatientsIcon className="sub-title-icon" />;
  } else if (cardType === "Meals") {
    iconToUse = <MealsIcon className="sub-title-icon" />;
  } else {
    iconToUse = <SymptomIcon className="sub-title-icon" />;
  }
  return (
    <div className={`statsCard ${short ? "stats-card-short" : ""}`}>
      <div className="stats-squareStudyContainer">
        {iconToUse}
        <div className="body-text-size stats-title">{cardType}</div>
      </div>
      <div className="stats-row row">
        <div>
          <div className="big-title-size stats-number">{data?.toString()}</div>
          {/* <div> Place holder for activity line </div> */}
        </div>
        <div className="body-text-size stats-bottomTitle">{label}</div>
      </div>
    </div>
  );
}

export default ActivityCard;
