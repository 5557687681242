import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  initNewMsalObject,
  signIn,
  getToken,
  // getAccessToken,
  isUserADoctor,
  isUserSignedIn,
  getUserName,
} from "../auth/auth";
import { initFetchRequest } from "../utils/helperFunctions";

//Material UI Imports
import { ThemeProvider, useMediaQuery } from "@mui/material";
import { theme } from "../utils/materialUI";
// Datepicker import

// Components
// import TopBar from "./navigation/TopBar";
import NavBar from "./navigation/NavBar";
//import LoadingIcon from "./common/LoadingIcon";
import Loading from "./common/Loading";
import HomePage from "../pages/HomePageResponsive";
import OnboardingPage from "../pages/OnboardingPage";
// import DoctorSymptomDashboard from "../pages/DoctorSymptomDashboard";
// import PatientProfilePage from "../pages/PatientList/PatientListPage";
// import IndividualProfile from "../pages/IndividualProfile";
import IndividualProfile from "../pages/DashboardPages/IndividualProfileDash";
// import AccountProfile from "../pages/AccountProfile";
// import RawDataPage from "../pages/RawDataPage";
import DoctorHomeDiary from "../pages/HomeDiary/DoctorHomeDiary";
import StudyDashboard from "../pages/DashboardPages/StudyDashboard";
import PatientDirectory from "../pages/PatientDirectory/PatientDirectory";
import SettingsPage from "../pages/AccountSettings/SettingsPage";

import IndependentUserProfileDashboard from "../pages/DashboardPages/IndependentUserProfileDashboard";

// React Icons
// import { MdDashboard } from "react-icons/md";
// import { FiUser } from "react-icons/fi";
// import { BsList } from "react-icons/bs";
// import { FaDev } from "react-icons/fa";
// import { ReactComponent as AccountIcon } from "../icons/AccountIcon.svg";
// import { ReactComponent as MessagesIcon } from "../icons/MessagesIcon.svg";
import { ReactComponent as HomeIcon } from "../icons/HomeIcon.svg";
import { ReactComponent as PatientDirectoryIcon } from "../icons/PatientDirectoryIcon.svg";
import { ReactComponent as StudyDashboardIcon } from "../icons/StudyDashboardIcon.svg";
import { ReactComponent as SettingsIcon } from "../icons/SettingsIcon.svg";

// CSS Imports
import "./App.css";
import {
  getAllStudyIDs,
  getAllStudyInformation,
  getStudyDashboardData,
} from "../API/HomeDiaryCalls";
import MobileWarningModal from "./common/MobileWarningModal";
import IndependentUserStudies from "../pages/Studies/IndependentUserStudies";
// import { FaDev } from "react-icons/fa";
// import { redirectUri } from "../auth/env";

// Custom TS Types
type FixMeLater = any; // TODO: Fix FixMeLater type
type NavRouteType = {
  path: string;
  exact: boolean;
  navIcon: JSX.Element;
  navText: string;
  main: JSX.Element;
}[];

type Study = {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<any>;
  };
  studyID: Number;
};

// Instantiate MSAL
initNewMsalObject(false); //user not logging in

function App() {
  // ---- App State ----
  const [, setIsSignedIn] = useState<boolean>(false);
  const [isDoc, setIsDoc] = useState<boolean | null>(null);
  const [, setUsername] = useState<string>("");
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [allPatientData] = useState<Object>();
  const [allStudyData, setAllStudyData] = useState<Study[]>([]);
  const isMobile = useMediaQuery("(max-width: 768px)");
  // ---- Navigation Routes ----
  // Default navigation routes included no matter what type the user is
  // const [] = useState<NavRouteType>([
  //   {
  //     path: "/api",
  //     exact: true,
  //     navIcon: <FaDev className="nav-icon icon-primary icon-secondary" />,
  //     navText: "API Tester",
  //     main: <RawDataPage getAccessToken={getAccessToken} />,
  //   },
  // ]);

  // Doctor navigation routes for when the type of user is a doctor
  const [doctorNavRoutes, setDoctorNavRoutes] = useState<NavRouteType>([
    // {
    //   path: "/messages",
    //   exact: true,
    //   navIcon: <HomeIcon className="nav-icon icon-primary icon-secondary" />,
    //   navText: "Messages",
    //   main: (
    //     <DoctorHomeDiary
    //     // logout={signOut}
    //     // getAccessToken={getAccessToken}
    //     // uri="/home"
    //     />
    //   ),
    // },
    // {
    //   path: "/dashboard",
    //   exact: true,
    //   navIcon: <StudyDashboardIcon className="nav-icon icon-primary icon-secondary" />,
    //   navText: "Study Dashboard",
    //   main: (
    //     <StudyDashboard
    //     // logout={signOut}
    //     // getAccessToken={getAccessToken}
    //     // uri="/dashboard"
    //     />
    //   ),
    // },
  ]);

  useEffect(() => {}, [allPatientData, allStudyData]);

  // Patient profile routes used
  const [patientProfileRoutes, setPatientProfileRoutes] =
    useState<NavRouteType>([]);

  // Account navigation route used when the type user
  const [accountRoute] = useState<NavRouteType>([]);

  // Messages navigation route used when the type user
  const [messageRoute] = useState<NavRouteType>([]);

  // Patient navigation routes for when the type of user is a patient
  const [patientNavRoutes, setPatientNavRoutes] = useState<NavRouteType>([]);

  // ---- Helper Functions ----

  /**
   * After the signIn function has been called, saves user access token in
   * local storage for use in api calls for the rest of session and calls
   * functions to populate either the doctor nav routes or patient nav routes.
   *
   * @param {Object} tokenResponse Object containing user access token.
   */
  const signInCallback = async (tokenResponse: FixMeLater) => {
    setIsSignedIn(true);
    // console.log(tokenResponse)
    try {
      const isDoctor = await isUserADoctor();
      setIsDoc(isDoctor);
      // Caching isDoctor so that we don't need to make repeat calls to
      // isUserADoctor() API call on page reloads. This cache will be
      // wiped when the user signs out
      localStorage.setItem("isDoctor", isDoctor.toString());

      if (isDoctor) {
        populateDoctorNavRoutes(tokenResponse);
      } else {
        populatePatientNavRoutes(tokenResponse);
      }
    } catch (err) {
      console.error(err);
    }
  };

  async function doesUserHaveValidSession() {
    let isSignedIn = isUserSignedIn();
    if (isSignedIn) {
      const tokenResponse = await getToken();
      let isDoctor = false;

      // on first signin, must cache isDoctor
      if (localStorage.getItem("isDoctor") == null) {
        isDoctor = await isUserADoctor();

        // Caching isDoctor so that we don't need to make repeat calls to
        // isUserADoctor() API call on page reloads. This cache will be
        // wiped when the user signs out
        localStorage.setItem("isDoctor", isDoctor.toString());
      } else {
        isDoctor = localStorage.getItem("isDoctor") === "true";
      }
      // Note: This code below is effectively casting the string "true"
      // or "false" from local storage to a boolean
      if (isDoctor) {
        setIsDoc(true);
        populateDoctorNavRoutes(tokenResponse);
      } else {
        populatePatientNavRoutes(tokenResponse);
      }
    } else if (
      window.localStorage["msal.error.description"] &&
      window.localStorage["msal.error.description"].includes("AADB2C90118")
    ) {
      initNewMsalObject(true);
      window.localStorage.clear();
    }
    return true;
  }

  // TODO: Can we lazy load this data?
  const populateDoctorNavRoutes = async (tokenResponse: FixMeLater) => {
    if (tokenResponse) {
      const patientsList = await getAllPatients(tokenResponse.accessToken);
      //console.log(patientsList)
      let studiesData: Study[] = [];
      const response = await getAllStudyIDs();
      if (response !== undefined) {
        const r = await getAllStudyInformation(response);
        studiesData = r.$values;
        // setAllStudyData(r.$values)
      }
      const dashBoardStudyData = await getStudyDashboardData(
        tokenResponse.accessToken
      );
      setPatientProfileRoutes(
        patientsList.map((patient: FixMeLater, index: number) => {
          // console.log(patient.patientID)
          return {
            path: `/user/profile/${patient.patientID}`,
            exact: true,
            main: (
              <IndividualProfile
                patients={patientsList}
                // getAccessToken={getAccessToken}
                // patient={patient}
              />
            ),
          };
        })
      );
      setAllStudyData(studiesData);
      addPatientProfilePageRouteToDoctorNavRoutes(
        patientsList,
        studiesData,
        dashBoardStudyData
      );
      // setUserAccountRoute(getUserName());
      // setUserMessageRoute(getUserName());

      // Update state
      setUsername(getUserName());
      setIsDataLoaded(true);
      //setPatientData(patientsList);
    }
  };

  const getAllPatients = async (accessToken: String) => {
    try {
      const request = initFetchRequest(
        "/api/Patients/getAllDoctorPatients",
        accessToken
      );
      const response = await fetch(request);
      const patientsList = await response.json();
      if (patientsList) {
        return patientsList;
      }
      return [];
    } catch (err) {
      console.error(err);
      // TODO: return []?
    }
  };

  const handleStudyChange = (newStudy: Study) => {
    setAllStudyData([...allStudyData, newStudy]);
  };

  const addPatientProfilePageRouteToDoctorNavRoutes = (
    patientsList: FixMeLater,
    allStudyData: Study[],
    dashBoardStudyData: any
  ) => {
    const newDoctorNavRoutes = doctorNavRoutes.concat([
      {
        path: "/",
        exact: true,
        navIcon: <HomeIcon className="nav-icon home-icon" />,
        navText: "Home Diary",
        main: (
          <DoctorHomeDiary
            patients={patientsList}
            allStudyData={allStudyData}
            handleStudyChange={handleStudyChange}
            setAllStudyData={setAllStudyData}
            dashBoardStudyData={dashBoardStudyData}
            // logout={signOut}
            // getAccessToken={getAccessToken}
            // uri="/home"
          />
        ),
      },
      {
        path: "/dashboard",
        exact: true,
        navIcon: (
          <StudyDashboardIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Study Dashboard",
        main: (
          <StudyDashboard
            patients={patientsList}
            allStudyData={allStudyData}
            dashBoardStudyData={dashBoardStudyData}
            // logout={signOut}
            // getAccessToken={getAccessToken}
            // uri="/dashboard"
          />
        ),
      },
      {
        path: "/patients",
        exact: true,
        navIcon: (
          <PatientDirectoryIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Patient Directory",
        main: (
          // <PatientProfilePage
          //   getAccessToken={getAccessToken}
          //   patients={patientsList}
          // />
          <PatientDirectory patients={patientsList} />
        ),
      },
      {
        path: "/settings",
        exact: true,
        navIcon: (
          <SettingsIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Account Settings",
        main: (
          <SettingsPage
          // patients={patientsList}
          // allStudyData={allStudyData}
          // logout={signOut}
          // getAccessToken={getAccessToken}
          // uri="/dashboard"
          />
        ),
      },
      //************** FOR DEVELOPMENT *************** */
      // {
      //   path: "/api",
      //   exact: true,
      //   navIcon: <FaDev className="nav-icon icon-primary icon-secondary" />,
      //   navText: "API Tester",
      //   main: <RawDataPage getAccessToken={getAccessToken} />,
      // },
    ]);
    setDoctorNavRoutes(newDoctorNavRoutes);
  };

  // const setUserAccountRoute = (username: string) => {
  //   setAccountRoute([
  //     {
  //       path: `/user/${username}/account`,
  //       exact: true,
  //       navIcon: <AccountIcon className="nav-icon icon-primary icon-secondary" />,
  //       navText: "Account",
  //       main: <AccountProfile name={username} />,
  //     },
  //   ]);
  // };

  // const setUserMessageRoute = (username: string) => {
  //   setMessageRoute([
  //     {
  //       path: `/user/${username}/account`,
  //       exact: true,
  //       navIcon: <MessagesIcon className="nav-icon icon-primary icon-secondary" />,
  //       navText: "Messages",
  //       main: <AccountProfile name={username} />,
  //     },
  //   ]);
  // };

  const populatePatientNavRoutes = (tokenResponse: FixMeLater) => {
    const userName = getUserName();
    //TODO: Change patient's paths when needed and fix home page
    setPatientNavRoutes([
      {
        path: "/",
        exact: true,
        navIcon: (
          <StudyDashboardIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "myDashboard",
        main: <IndependentUserProfileDashboard />,
      },
      {
        path: "/studies",
        exact: true,
        navIcon: (
          <PatientDirectoryIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Studies",
        main: <IndependentUserStudies />,
      },
      {
        path: "/settings",
        exact: true,
        navIcon: (
          <SettingsIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Account Settings",
        main: (
          <SettingsPage
          // logout={signOut}
          // getAccessToken={getAccessToken}
          // uri="/dashboard"
          />
        ),
      },
    ]);

    setUsername(userName);
    // setUserAccountRoute(userName);
    // setUserMessageRoute(userName);
    setIsDataLoaded(true);
  };

  // On first component mount, check if a valid user session exists
  // and automatically redirect user to dashboard.
  useEffect(() => {
    doesUserHaveValidSession();

    // Below comment silences a warning from React
    // TODO: Be a good React dev and follow the React-y way
    //       addressing this warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---- Render JSX Elements ----

  // If signed in, go to the dashboard
  if (isUserSignedIn()) {
    if (!isDataLoaded) {
      return (
        <div className="loadingContainer">
          <Loading />
        </div>
      );
    }

    const userNavRoutes: NavRouteType = [];
    if (isDoc) {
      userNavRoutes.push(
        ...doctorNavRoutes,
        ...patientProfileRoutes,
        ...messageRoute,
        ...accountRoute
        // ...defaultNavRoutes,
      );
    } else {
      userNavRoutes.push(
        ...patientNavRoutes,
        ...messageRoute,
        ...accountRoute
        // ...defaultNavRoutes
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <>
          <Router>
            <div className="navbarDiv">
              {isDoc ? (
                <NavBar
                  routes={[
                    ...doctorNavRoutes,
                    ...messageRoute,
                    ...accountRoute,
                    // ...defaultNavRoutes
                  ]}
                />
              ) : (
                <NavBar
                  routes={[
                    ...patientNavRoutes,
                    ...messageRoute,
                    ...accountRoute,
                    // ...defaultNavRoutes,
                  ]}
                />
              )}
            </div>
            <Routes>
              {userNavRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.main}
                />
              ))}
            </Routes>
          </Router>
          {isMobile ? <MobileWarningModal /> : null}
        </>
      </ThemeProvider>
    );
  }

  // If the user is not logged in, render the home routes
  const homeRoutes = [
    {
      path: "/",
      main: (
        <HomePage
          signin={signIn}
          tokenCallback={getToken}
          updateCallback={signInCallback}
        />
      ),
    },
    {
      path: "/onboarding",
      main: (
        <OnboardingPage
          signin={signIn}
          tokenCallback={getToken}
          updateCallback={signInCallback}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Router>
        <Routes>
          {homeRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.main} />
          ))}
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
