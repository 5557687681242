import { getAccessToken } from "../auth/auth";
import {
  initPostRequest,
  initFetchRequest,
  initDeleteRequest,
} from "../utils/helperFunctions";
import { fetchData } from "../utils/helperFunctions";

// This function is used to post a new study for a doctor
export const postNewStudy = async (
  studyID,
  studyName,
  studyDescription,
  patients
) => {
  var token = await getAccessToken();
  var body = {
    StudyID: studyID,
    Name: studyName,
    Abstract: studyDescription,
    Patients: patients,
  };
  var request = initPostRequest("/api/study", token, JSON.stringify(body));
  try {
    await fetchData(request);
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const getAllStudyIDs = async () => {
  var token = await getAccessToken();
  var request = initFetchRequest(
    "/api/Study/GetUsersStudiesInformation",
    token
  );
  try {
    var response = await fetchData(request).then((value) => {
      if (value.$values !== undefined) {
        return value.$values.map((v) => v.idStudies);
      }
    });
    return response;
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const getAllUserStudyInformation = async () => {
  var token = await getAccessToken();
  var request = initFetchRequest(
    "/api/Study/GetUsersStudiesInformation",
    token
  );
  try {
    var response = await fetchData(request);
    return response.$values;
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const getAllStudyInformation = async (ids) => {
  var body = {
    studyIds: ids,
  };
  var token = await getAccessToken();
  var request = initPostRequest(
    "/api/study/GetStudiesInformation",
    token,
    JSON.stringify(body)
  );
  try {
    var response = await fetchData(request);
    return response;
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const getStudyDashboardData = async (token) => {
  var request = initFetchRequest(
    "/api/Study/GetWebsiteStudyDashboardData",
    token
  );
  try {
    var response = await fetchData(request);
    return response;
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const getSpecificStudy = async (id) => {
  var token = await getAccessToken();
  var request = initFetchRequest("/api/Study/GetSpecificStudy/" + id, token);
  try {
    var response = await fetchData(request).then((value) => value.$values);
    return response;
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const deleteStudy = async (id) => {
  var token = await getAccessToken();
  var request = initDeleteRequest("/api/study/" + id, token);
  try {
    var response = await fetchData(request);
    return response;
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

export const sendDoctorToPatientEmail = async (to, subject, emailBody) => {
  var token = await getAccessToken();
  var body = {
    emails: [to],
    subject: subject,
    body: emailBody,
  };
  var request = initPostRequest(
    "/api/DoctorsToPatients/SendEmailInvites",
    token,
    JSON.stringify(body)
  );
  try {
    await fetchData(request);
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};

// Join a study by code
export const joinStudyByCode = async (code) => {
  var token = await getAccessToken();
  var body = code;
  var request = initPostRequest(
    "/api/Study/JoinByCode",
    token,
    JSON.stringify(body)
  );
  try {
    await fetchData(request);
  } catch (err) {
    console.log("Error calling the Web api:\n" + err);
  }
};
