import { BarChart } from "@mui/x-charts/BarChart";

const CaloriesBarGraph = ({ graphData }: { graphData: any }) => {
  const data = graphData.map((data: any) => ({
    totalCalories: data.calories,
    date: data.date,
  }));

  return (
    <BarChart
      dataset={data}
      // height={500}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "date",
        },
      ]}
      series={[
        { dataKey: "totalCalories", label: "Calories", color: "#116E87" },
      ]}
    />
  );
};

export default CaloriesBarGraph;
