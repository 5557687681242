import "./Logo.css";


function Logo() {
  const svgLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" id="svg-logo">
      <g id="Layer_1" data-name="Layer 1">
        <rect className="cls-1" width="216" height="216" rx="50.43" />
      </g>
      <g id="Layer_5" data-name="Layer 5">
        <path
          className="cls-2"
          d="M148.87,79.15c-1.87,27.52-19.36,42.32-40.09,42.32-21.27,0-36.78-14.8-38.71-42.32-1.4-20,14.38-46,38.93-46.15C130.59,32.84,150.45,55.7,148.87,79.15Z"
        />
        <path
          className="cls-2"
          d="M194,189A19.62,19.62,0,0,1,177.65,198H39.18a19,19,0,0,1-9.39-2.5c-4-14.13,7.91-39.78,25.14-49.76,13.93-8.06,21.33,2.8,32.06-13.23,7.1-10.62,0-31,6.79-29.32,11.21,2.79,26.69-10.87,32.39-2,5.56,8.71-1.4,19,5.33,29.54,9.89,15.52,21.43,5.17,37.24,14C183.69,153.14,194.38,173.89,194,189Z"
        />
        <path
          className="cls-3"
          d="M70,71c2.59,3.87,7.86,8.66,16.66,13.38,21.41,11.48,43.21.48,44.23,0,10.37-4.84,15.06-9.31,18-13.82"
        />
        <path
          className="cls-4"
          d="M81,81c-2.76-1.38-6,27,4,34,14.53,10.17,30,11.59,47,1,12-8,7.19-33,4.42-34.39,0,0-14.89,7-28.33,7.61C93.92,89.89,81,81,81,81Z"
        />
        <path
          className="cls-3"
          d="M86,114c-4-4-9.11-9.3-12-17-1-2.76-2.5-8.12-2.5-9.5"
        />
        <path
          className="cls-3"
          d="M147.5,87.5C145,94,145,95,143,100s-5.5,8.5-9,12"
        />
        <ellipse
          className="cls-2"
          cx="149.93"
          cy="78.88"
          rx="8.34"
          ry="4.59"
          transform="translate(43.39 210.61) rotate(-78.79)"
        />
        <ellipse
          className="cls-2"
          cx="69.42"
          cy="79.14"
          rx="4.68"
          ry="8.26"
          transform="translate(-10.79 10.96) rotate(-8.38)"
        />
      </g>
      <g id="Layer_6" data-name="Layer 6">
        <ellipse
          className="cls-5"
          cx="128.82"
          cy="179.49"
          rx="6.61"
          ry="7.49"
        />
        <ellipse
          className="cls-5"
          cx="140.14"
          cy="179.49"
          rx="6.61"
          ry="7.49"
        />
        <polygon
          className="cls-5"
          points="124.1 184.68 134.35 196 144.86 184.68 124.1 184.68"
        />
        <rect
          className="cls-5"
          x="131.65"
          y="181.85"
          width="5.66"
          height="3.77"
          rx="0.89"
        />
      </g>
      <g id="Layer_4" data-name="Layer 4">
        <circle className="cls-6" cx="134.5" cy="183.55" r="3.55" />
        <line className="cls-7" x1="137.1" y1="183.35" x2="193.5" y2="183.5" />
        <rect
          className="cls-8"
          x="14"
          y="14"
          width="189"
          height="188"
          rx="44.83"
        />
        <circle className="cls-6" cx="109.22" cy="136.55" r="3.55" />
        <line className="cls-7" x1="106.62" y1="136.34" x2="16.5" y2="136.5" />
      </g>
    </svg>
  );

  return (
    <div id="logo-container">
      {svgLogo}
      <span>
        <span id="logo-title" style={{display: "inline"}}>
            myMedDiary
          <span
            style={{
              fontSize: "12px",
              display: "inline",
              verticalAlign: "top",
            }}>
            &reg;
          </span>
        </span>
        {/* <SignOutButton callback={signOut}/> */}
      </span>
    </div>
  );
}

export default Logo;
