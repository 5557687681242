import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Button from "@mui/material/Button";
import { Spin as Hamburger } from "hamburger-react";
import Logo from "../../img/logo.svg";
import "./HomeNavBarResponsive.css";
import GradientButton from "../common/GradientButton";
import { ReactComponent as PlayStore } from "../../icons/android-logo.svg";
import { ReactComponent as AppStore } from "../../icons/app-store.svg";

type FixMeLater = any;

type Props = {
  signin: () => void;
  tokenCallback?: () => void;
  updateCallback?: (tokenResponse: FixMeLater) => Promise<void>;
};

function HomeNavBar({ signin, tokenCallback, updateCallback }: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pageWidth, setDimensions] = useState(window.innerWidth);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <>
      {pageWidth < 769 ? (
        <nav id="mobile-navbar-container">
          <div id="mobile-navbar-title-container">
            <Link id="desktop-navbar-logo-link" to="/">
              <section id="desktop-navbar-title-container">
                <img
                  id="desktop-navbar-logo"
                  src={Logo}
                  alt="myMedDiary logo."
                />
                <p id="desktop-navbar-title">myMedDiary</p>
              </section>
            </Link>
            <div style={{ zIndex: 100 }}>
              <Hamburger
                toggled={menuOpen}
                toggle={setMenuOpen}
                size={30}
                label="Show menu"
                color="#5FBDD6"
              />
            </div>
          </div>
          <Menu
            right
            id="mobile-navbar-menu"
            isOpen={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}>
            <ul id="mobile-navbar-link-container">
              <li>
                <Link
                  onClick={() => closeMenu()}
                  className="navbar-link"
                  to="/">
                  HOME
                </Link>
              </li>
              {/*<li>
                <Link
                  onClick={() => closeMenu()}
                  className="navbar-link"
                  to="/onboarding">
                  HOW IT WORKS
                </Link>
              </li>*/}
              <li>
                <a
                  href="https://apps.apple.com/us/app/mymeddiary/id1506742164"
                  className="navbar-link">
                  DOWNLOAD FOR IOS
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ComputingReApplied.myMedDiary&hl=en_US"
                  className="navbar-link">
                  DOWNLOAD FOR PlayStore
                </a>
              </li>
              <li>
                <Button
                  id="mobile-navbar-signin-btn"
                  onClick={signin}
                  variant="contained">
                  Sign In
                </Button>
              </li>
            </ul>
          </Menu>
        </nav>
      ) : (
        <nav id="desktop-navbar-container">
          <section className="flex-row">
            <Link id="desktop-navbar-logo-link" to="/">
              <section id="desktop-navbar-title-container">
                <img
                  id="desktop-navbar-logo"
                  src={Logo}
                  alt="myMedDiary logo."
                />
                <p id="desktop-navbar-title">myMedDiary</p>
              </section>
            </Link>
          </section>
          <section id="mobile-app-link-container">
            <a
              className="m-0 p-0 h-fit"
              href="https://play.google.com/store/apps/details?id=com.ComputingReApplied.myMedDiary">
              <PlayStore className="w-10" />
            </a>
            <a
              className="m-0 p-0 h-fit"
              href="https://apps.apple.com/us/app/mymeddiary/id1506742164">
              <AppStore className="w-10" />
            </a>

            <GradientButton onClick={signin} text={"Sign In"} />
          </section>
        </nav>
      )}
    </>
  );
}

export default HomeNavBar;
