import LoadingIcon from "./LoadingIcon";
import "./Loading.css";
import "../../overall.css";

function Loading() {
    return (
      <div>
        <LoadingIcon />
        <div className="body-text-size body-text mrt-24">Thank you for your patience. myMedDiary is loading.</div>
      </div>
    );
  }
  
  export default Loading;
  