import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../common/Logo";

import "./NavBar.css";
import "../../overall.css";
import { getSignedInUserFullName, isUserADoctor } from "../../auth/auth";
import UpgradeModal from "../upgradeModal/UpgradeModal";
import GradientButton from "../common/GradientButton";

type NavRouteType = {
  path: string;
  exact: boolean;
  navIcon: JSX.Element;
  navText: string;
  main: JSX.Element;
}[];

type Props = {
  routes: NavRouteType;
};

function NavBar({ routes }: Props) {
  const [isDoctor, setIsDoctor] = useState<boolean | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const userFullName = getSignedInUserFullName();

  useEffect(() => {
    const checkDoctorStatus = async () => {
      const doctorStatus = await isUserADoctor();
      setIsDoctor(doctorStatus);
    };

    checkDoctorStatus();
  }, []);

  const handleUpgradeClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <nav className="navbar-container box-container col-san-full ">
        <section className="section">
          <Logo />
          <ul className="navbar-list">
            {routes.map((route, index) => (
              <li className="navbar-item" key={index}>
                <Link to={route.path} className="navbar-link">
                  {route.navIcon} {route.navText}
                </Link>
              </li>
            ))}
          </ul>
        </section>

        <section className="section nav-user-information">
          <div className="mb-10">
            <h2 className="text-xl mb-1 font-bold">{userFullName}</h2>
            <h3 className="text-xs mb-1">
              FREE {isDoctor ? "RESEARCHER" : "INDIVIDUAL"} PLAN
            </h3>
            {!isDoctor ? (
              <>
                <h4 className="text-gray-500">Upgrade to myMedDiary </h4>
                <h4 className="text-gray-500">Teams</h4>
              </>
            ) : null}
          </div>
          {!isDoctor ? (
            <GradientButton
              text="Upgrade Plan"
              onClick={handleUpgradeClick}
              additionalClasses="w-52 "
            />
          ) : null}
        </section>
      </nav>
      {showModal ? (
        <UpgradeModal show={showModal} onClose={() => setShowModal(false)} />
      ) : null}
    </>
  );
}

export default NavBar;
