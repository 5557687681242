import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import "./GradientButtonCheckout.css";

interface Props {
  onClick: () => void;
  priceRecurrence: string;
  recurrence: string;
}

const GradientButtonCheckout = ({
  onClick,
  priceRecurrence,
  recurrence,
}: Props) => {
  return (
    <div onClick={onClick} className={`gradient-button-checkout`}>
      <div className="flex justify-between items-center w-full">
        <section className="block">
          <h3>Proceed to Checkout</h3>
          <p className="font-light text-sm leading-4">{priceRecurrence}</p>
          <p className="font-light text-sm leading-4">paid {recurrence}</p>
        </section>
        <section className="block">
          <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
        </section>
      </div>
    </div>
  );
};

export default GradientButtonCheckout;
