import { Modal, Select, MenuItem } from "@mui/material";
import "./UpgradeModal.css";
import GradientButtonCheckout from "./GradientButtonCheckout";
import { useEffect, useState } from "react";
import Frequency from "./Frequency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  show: boolean;
  onClose: () => void;
}

const constants = {
  month: "month",
  monthly: "monthly",
  annualy: "annualy",
  annual: "annual",
  individual: "individual",
  team: "team",
};

const UpgradeModal = ({ show, onClose }: Props) => {
  const [frequency, setFrequency] = useState(constants.month);
  const [licenceType /*setLicenceType*/] = useState(constants.individual);
  const [hipaaProtected, setHipaaProtected] = useState(false);
  const [teamSeats, setTeamSeats] = useState(0);
  const [upgraded, setUpgraded] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [annualPrice, setAnnualPrice] = useState(0);
  const [displayMonthlyPrice, setDisplayMonthlyPrice] = useState(0);
  const [displayAnnualPrice, setDisplayAnnualPrice] = useState(0);
  const currencies = ["USD"];

  useEffect(() => {
    if (hipaaProtected) {
      setMonthlyPrice(270 * (teamSeats + 1));
      setAnnualPrice(3000 * (teamSeats + 1));
      setDisplayMonthlyPrice(270);
      setDisplayAnnualPrice(3000);
    } else {
      setMonthlyPrice(0);
      setAnnualPrice(0);
      setDisplayMonthlyPrice(75);
      setDisplayAnnualPrice(800);
    }
  }, [hipaaProtected, teamSeats, licenceType]);

  useEffect(() => {
    if (licenceType === constants.individual) {
      setTeamSeats(0);
    }
  }, [licenceType]);

  const handleCheckoutClick = () => {
    window.open("https://forms.office.com/r/3RzQAbi2Hh", "_blank");
    setUpgraded(licenceType);
  };

  /* const handleSwitchLicenceType = (
    e: React.MouseEvent<HTMLParagraphElement>
  ) => {
    e.preventDefault();
    setLicenceType(
      licenceType === constants.individual
        ? constants.team
        : constants.individual
    );
  };*/

  const renderFeatures = (): JSX.Element => {
    if (licenceType === constants.team) {
      if (hipaaProtected) {
        return (
          <>
            <li>HIPAA Data Collection</li>
            <li>
              Tailor UI/UX with ease for Diverse Studies and Clinical
              Applications
            </li>
            <li>Complex Data Analysis</li>
            <li>Unlimited participant mobile codes</li>
          </>
        );
      } else {
        return (
          <>
            <li>Standard Nutritional + Symptomatic Data Collection</li>
            <li>Basic Data Analysis</li>
            <li>10 participant mobile codes</li>
          </>
        );
      }
    } else {
      if (hipaaProtected) {
        return (
          <>
            <li>HIPAA protected Nutritional + Symptomatic Data Collection</li>
            <li>Connect to medical records</li>
          </>
        );
      } else {
        return (
          <>
            <li>Standard Nutritional + Symptomatic Data Collection</li>
            <li>Basic Data Analysis</li>
          </>
        );
      }
    }
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="mobile-warning"
      aria-describedby="mobile-warning-description">
      {!upgraded ? (
        <div className="upgrade-modal box-container absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-14 w-[935px] h-fit grid  grid-cols-2 gap-32">
          <div className="column left flex flex-column gap-3 ">
            {licenceType === constants.individual ? (
              <span className="modal-title">
                <h1 className="text-3xl font-extrabold ">
                  myMedDiary Individual
                </h1>
                <h1 className="text-3xl font-extrabold ">License</h1>
              </span>
            ) : (
              <span className="modal-title">
                <h1 className="text-3xl font-extrabold ">Upgrade to</h1>
                <h1 className="text-3xl font-extrabold ">myMedDiary Teams</h1>
              </span>
            )}

            <div className="flex align-center border border-solid border-gray-300 rounded-[8px] text-sm font-bold cursor-pointer">
              <span
                onClick={() => setHipaaProtected(false)}
                className={`plan border-r border-gray-300 w-1/2 p-2 rounded-tl-[8px] rounded-bl-[8px] text-center ${!hipaaProtected ? "selected" : ""}`}>
                Standard Plan
              </span>
              <span
                onClick={() => setHipaaProtected(true)}
                className={`plan border-l border-gray-300 w-1/2 p-2 rounded-tr-[8px] rounded-br-[8px] text-center  ${hipaaProtected ? "selected" : ""}`}>
                HIPAA protected
              </span>
            </div>

            <div className="flex justify-between align-center h-[3.5rem]">
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                variant="outlined"
                className="h-12 w-24">
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
              <Frequency
                checked={frequency === constants.month}
                handleClick={() => setFrequency(constants.month)}
                frequency={constants.monthly}
                priceFrequency={`${displayMonthlyPrice}/month`}
                freeTrial={hipaaProtected ? "" : "a limited time"}
              />
              <Frequency
                checked={frequency === constants.annual}
                handleClick={() => setFrequency(constants.annual)}
                frequency={constants.annual}
                priceFrequency={`${displayAnnualPrice.toLocaleString("en-us")}/year`}
                freeTrial={hipaaProtected ? "" : "a limited time"}
              />
            </div>

            <div className="flex justify-between align-center pb-2 pt-2 h-[3.5rem]">
              {licenceType === constants.team ? (
                <>
                  <section>
                    <h3 className="text-sm">Team Seats</h3>
                    <p className={"text-xs font-light"}>
                      Team Members get access to myMedDiary Teams
                    </p>
                  </section>
                  <section className="flex space-between items-center gap-3 font-extrabold text-center">
                    <FontAwesomeIcon
                      style={{
                        fontSize: "30px",
                        color: "#C4C4C4",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        teamSeats > 0 && setTeamSeats(teamSeats - 1)
                      }
                      icon={faMinusSquare}
                    />
                    {teamSeats}
                    <FontAwesomeIcon
                      style={{
                        fontSize: "30px",
                        color: "#7ECFE4",
                        cursor: "pointer",
                      }}
                      onClick={() => setTeamSeats(teamSeats + 1)}
                      icon={faPlusSquare}
                    />
                  </section>
                </>
              ) : null}
            </div>

            <GradientButtonCheckout
              onClick={handleCheckoutClick}
              priceRecurrence={
                frequency === constants.month
                  ? `$${monthlyPrice.toLocaleString("en-us")}/${constants.month}`
                  : `$${annualPrice.toLocaleString("en-us")}/${constants.annual}`
              }
              recurrence={
                frequency === constants.month
                  ? constants.monthly
                  : constants.annualy
              }
            />
          </div>
          <div className="column right">
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#7ecfe4",
              }}>
              <CloseIcon />
            </IconButton>
            <span className="text-right relative top-[-20px] left-[-15px]">
              {/* <p
                onClick={handleSwitchLicenceType}
                className="p-0 m-0 text-gray-400 text-md font-bold align-center hover:no-underline hover:text-gray-500 cursor-pointer ">
                <FontAwesomeIcon icon={faRightLeft} className="pr-1" /> Switch
                to{" "}
                {licenceType === constants.individual
                  ? "Teams Licence"
                  : "Individual Licence"}
              </p> */}
            </span>
            <div className="text-lg pl-0">
              {licenceType === constants.individual ? (
                <>
                  <p className="font-bold m-0 leading-5">
                    Log and view Nutritional and
                  </p>
                  <p className="font-bold m-0 leading-5">
                    Symptomatic Data on MMD Mobile
                  </p>
                  <p className="font-bold m-0 leading-5">and Web App</p>
                </>
              ) : (
                <>
                  <p className="font-bold m-0 leading-5">
                    Create studies and invite participants
                  </p>
                  <p className="font-bold m-0 leading-5">
                    to gather data in a HIPAA protected
                  </p>
                  <p className="font-bold m-0 leading-5">online environment.</p>
                </>
              )}
            </div>
            <div className="mt-10">
              <ul className="list-disc text-gray-500 pl-3 w-[90%] font-bold leading-5 space-y-3">
                {renderFeatures()}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="upgrade-modal box-container absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-14 w-fit h-fit text-center">
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#7ecfe4",
            }}>
            <CloseIcon />
          </IconButton>
          <h3>You will be upgraded within 24 hours</h3>
          <h3>A confirmation email will be sent to you when done</h3>
        </div>
      )}
    </Modal>
  );
};

export default UpgradeModal;
