import { LineChart } from "@mui/x-charts/LineChart";

const CaloriesLineGraph = ({ graphData }: { graphData: any[] }) => {
  const data = graphData.map((data: any) => ({
    totalCalories: parseFloat(data.calories) || 0,
    date: data.date,
  }));

  return (
    <LineChart
      dataset={data}
      // height={500}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "date",
        },
      ]}
      series={[
        { dataKey: "totalCalories", label: "Calories", color: "#116E87" },
      ]}
    />
  );
};

export default CaloriesLineGraph;
