import { Checkbox } from "@mui/material";
import "./Frequency.css";

interface Props {
  handleClick: () => void;
  checked: boolean;
  frequency: string;
  priceFrequency: string;
  freeTrial?: string;
}

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Frequency = ({
  handleClick,
  checked,
  frequency,
  priceFrequency,
  freeTrial,
}: Props) => {
  return (
    <div className="frequency pt-2">
      <div className="flex align-start gap-2 w-[7rem]">
        <Checkbox
          sx={{
            "height": "fit-content",
            "padding": 0,
            "&.Mui-checked": {
              color: "#5FBDD6",
            },
            "color": "#5FBDD6",
          }}
          checked={checked}
          onClick={handleClick}
          inputProps={{ "aria-label": "controlled" }}
        />
        <div className="flex flex-col">
          <h1 className="text-sm mt-0 mb-1 text-gray-500 font-bold">
            {capitalizeFirstLetter(frequency.toLowerCase())}
          </h1>
          <p
            className={`text-xs font-bold text-gray-500 ${freeTrial ? "line-through" : ""}`}>
            ${priceFrequency}
          </p>
        </div>
      </div>
      {freeTrial && (
        <p className="small-text text-gray-600 font-light text-right">{`Free for ${freeTrial}`}</p>
      )}
    </div>
  );
};

export default Frequency;
