import React from 'react';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import '../GraphDisplay.css'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    background: '#FAFAFA'
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FAFAFA',
    color: '#5FBDD6',
    border: '1px solid #FAFAFA',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '10px 26px 10px 12px',
    marginLeft: '-10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 6,
      borderColor: '#5FBDD6'
    },
  },
}));

const GenderDropDown = (props) => {
  return (
    <div>
      <FormControl sx={{ margin: 1, minWidth: 170, width: '90%' }}>
        <NativeSelect
          id="demo-customized-select-native"
          onChange={props.onChangeFunction}
          input={<BootstrapInput />}
        >
          <option value="" disabled selected>Select</option>
          <option value={"all"}>All</option>
          <option value={"male"}>Male</option>
          <option value={"female"}>Female</option>
          <option value={"other"}>Other</option>
        </NativeSelect>
      </FormControl>
    </div>
  );
}

export default GenderDropDown;
