import React, { ChangeEvent, useState, useEffect } from "react";
import TitleBar from "../../components/common/TitleBar";
//import { ReactComponent as AddPatientIcon } from "../../icons/AddPatientFig.svg";
//import { ReactComponent as AddStudyIcon } from "../../icons/AddStudyFig.svg";
import { ReactComponent as StudyIcon } from "../../icons/StudyIconFig.svg";
import LoadingIcon from "../../components/common/LoadingIcon";

//icon imports
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ReactComponent as PatientsIcon } from "../../icons/PatientsIconFig.svg";
import { ReactComponent as SymptomIcon } from "../../icons/SymptomsIconFig.svg";
import { ReactComponent as BellIcon } from "../../icons/BellIconFig.svg";
import { ReactComponent as MealsIcon } from "../../icons/MealsIconFig.svg";

// Material UI imports
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import PatientActivityGraph from "./Graphs/PatientActivityGraph";
import NewStudyModal from "./Modals/NewStudyModal/NewStudyModal";
import AddPatientModal from "./Modals/AddPatientModal";

import { deleteStudy } from "../../API/HomeDiaryCalls";
import "./DoctorHomeDiary.css";
import "../../overall.css";

type HomeDashProps = {
  patients: Object;
  allStudyData: Study[];
  handleStudyChange: (newStudy: Study) => void;
  setAllStudyData: React.Dispatch<React.SetStateAction<Study[]>>;
  dashBoardStudyData: any;
};
const data = [
  {
    id: "japan",
    color: "#E3AD84",
    data: [
      {
        x: "plane",
        y: 295,
      },
      {
        x: "helicopter",
        y: 233,
      },
      {
        x: "boat",
        y: 218,
      },
      {
        x: "train",
        y: 6,
      },
      {
        x: "subway",
        y: 106,
      },
      {
        x: "bus",
        y: 103,
      },
      {
        x: "car",
        y: 110,
      },
      {
        x: "moto",
        y: 148,
      },
      {
        x: "bicycle",
        y: 253,
      },
      {
        x: "horse",
        y: 196,
      },
      {
        x: "skateboard",
        y: 50,
      },
      {
        x: "others",
        y: 286,
      },
    ],
  },
];

type Study = {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<any>;
  };
  studyID: Number;
};

//TODO: Matt -> Refactor study container and break up into components
function DoctorHomeDiary({
  patients,
  allStudyData,
  handleStudyChange,
  setAllStudyData,
  dashBoardStudyData,
}: HomeDashProps) {
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [newStudyModalOpen, setNewStudyModalOpen] = useState(false);
  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);

  const [deleteStudyModal, setDeleteStudyModal] = useState(false);
  const [deleteStudyTextInput, setDeleteStudyTextInput] = useState("");
  // const [deleteStudyNameMatch, setDeleteStudyNameMatch] = useState<String>("");
  const [currentKey, setCurrentKey] = useState(0);
  const [dialogueTitleDynamic, setDialogueTitleDynamic] = useState("");
  //console.log(handleStudyChange)
  // For handling modal that creates new study
  const handleNewStudyOpen = () => {
    setNewStudyModalOpen(true);
  };

  const handleAddPatientOpen = () => {
    setAddPatientModalOpen(true);
  };

  // Functions for handling modal that pops up when deleting a study
  const handleDeleteStudyModalOpen = () => {
    setDeleteStudyModal(true);
  };
  const handleDeleteStudyModalClose = () => {
    setDeleteStudyModal(false);
  };

  const handleDeleteStudyTextInput = (event: ChangeEvent) => {
    setDeleteStudyTextInput(
      (event.nativeEvent.target as HTMLInputElement).value
    );
  };

  const handleSubmitDelete = async () => {
    setIsLoaded(false);
    if (allStudyData[currentKey].studyID === -1) {
      setAllStudyData(allStudyData.splice(currentKey, 1));
      handleDeleteStudyModalClose();
    } else if (deleteStudyTextInput === allStudyData[currentKey].name) {
      var res = await deleteStudy(allStudyData[currentKey].studyID);
      if (res === "Study Deleted") {
        setAllStudyData(allStudyData.splice(currentKey, 1));
        handleDeleteStudyModalClose();
      }
    }
    window.location.reload();
  };

  const handlePostStudy = (study: any) => {
    //console.log(study)
    // let temp: Study = {
    //     abstract: study.abstract,
    //     name: study.name,
    //     patients: {
    //         $id: "",
    //         $values: study.patients
    //     }
    //     ,
    //     studyID: -1,
    // };
    window.location.reload();
  };

  useEffect(() => {}, [allStudyData]);

  function contentToShow() {
    if (!isLoaded) {
      return (
        <div style={{ marginTop: "25%" }}>
          <LoadingIcon></LoadingIcon>
        </div>
      );
    } else if (allStudyData) {
      return (
        <div>
          <TitleBar title={"Professional Dashboard"} />
          <div className="sumContainer row">
            <div className="sumLeftCardsContainer box-container">
              <div className="sub-title-size sub-title mrb-12">Summary</div>
              <div className="leftSumFlexContainer">
                <div className="stats-container row">
                  <div className="statsCard">
                    <div className="stats-squareStudyContainer">
                      <StudyIcon className="sub-title-icon" />
                      <div className="body-text-size stats-title">Studies</div>
                    </div>
                    <div className="stats-row row">
                      <div className="big-title-size stats-number">
                        {dashBoardStudyData.currentlyActiveStudy || 0}
                      </div>
                    </div>
                    <div className="body-text-size stats-bottomTitle">
                      Active Currently
                    </div>
                  </div>

                  <div className="statsCard">
                    <div className="stats-squareStudyContainer">
                      <PatientsIcon className="sub-title-icon" />
                      <div className="body-text-size stats-title">Patients</div>
                    </div>
                    <div className="stats-row row">
                      <div className="big-title-size stats-number">1</div>
                    </div>
                    <div className="body-text-size stats-bottomTitle">
                      Active Currently
                    </div>
                  </div>

                  <div className="statsCard">
                    <div className="stats-squareStudyContainer">
                      <StudyIcon className="sub-title-icon" />
                      <div className="body-text-size stats-title">Patients</div>
                    </div>
                    <div className="stats-row row">
                      <div className="big-title-size stats-number">
                        {dashBoardStudyData.registeredPatients || 0}
                      </div>
                    </div>
                    <div className="body-text-size stats-bottomTitle">
                      Registered
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-container sumRightCardsContainer mrl-20">
              <div className="sumRightHeaderContainer">
                <div className="row edit-row">
                  <div className="sub-title-size sub-title mrb-12">
                    Quick Add
                  </div>
                  <div className="edit-button">
                    <div className="text-button mrb-12">Edit</div>
                  </div>
                </div>
              </div>
              <button
                className="secondary-button mrb-12"
                onClick={handleNewStudyOpen}>
                <div className="body-text-size">+ Add a new study</div>
              </button>
              <button
                className="secondary-button"
                onClick={handleAddPatientOpen}>
                <div className="body-text-size">+ Add a new patient</div>
              </button>
            </div>
          </div>

          {[...allStudyData].map((studyData, key) => {
            let patientsEnrolled = 0;
            let totalSymtpoms = 0;
            let totalMealsLogged = 0;
            let recentlyActive;
            let recentlyLoggedMeals;
            let recentlyLoggedSymptoms;
            Object.entries(dashBoardStudyData)[1].forEach((value: any) => {
              if (value[studyData.studyID as number]) {
                recentlyLoggedMeals =
                  value[studyData.studyID as number].mealsWeeklyRecentlyLogged;
                recentlyActive =
                  value[studyData.studyID as number]
                    .patientsWeeklyRecentlyActive;
                recentlyLoggedSymptoms =
                  value[studyData.studyID as number]
                    .symptomsWeeklyRecentlyLogged;
                totalMealsLogged =
                  value[studyData.studyID as number].totalMealsLogged;
                totalSymtpoms =
                  value[studyData.studyID as number].totalSymptomsLogged;
                patientsEnrolled =
                  value[studyData.studyID as number].patientsEnrolled;
              }
            });
            if (studyData.patients.$values !== undefined) {
              return (
                <div
                  onPointerOver={() => {
                    setCurrentKey(key);
                    if (allStudyData[currentKey] !== undefined) {
                      setDialogueTitleDynamic(
                        allStudyData[currentKey].name as string
                      );
                    }
                  }}
                  key={key}
                  className="studyMainContainer">
                  <div className="box-leftContainer studyLeftContainer">
                    <div className="sub-title-size sub-title mrb-12">
                      Study Information
                    </div>
                    <div className="drawer mrb-12">
                      <div className="drawer-container">
                        <div className="drawer-title body-text-size">
                          {studyData.name}
                        </div>{" "}
                        {/* TODO: This needs to be dynamic */}
                        <div className="drawer-text body-text-size">
                          Mar 2021-Current
                        </div>{" "}
                        {/* TODO: This needs to be dynamic */}
                        <div className="drawer-text body-text-size">
                          Single Blind
                        </div>
                      </div>
                      <div className="studyIconContainer">
                        <PlayArrowIcon className="studyIcons" />
                      </div>
                    </div>
                    <div className="sub-title-size sub-title mrb-12 mrt-24">
                      Study Summary
                    </div>
                    <div className="row">
                      <div className="numberCard">
                        <div className="number-cardDataContainer">
                          <div className="row">
                            <PatientsIcon className="sub-title-icon number-cardIcon" />
                            <div className="number-cardData big-title-size">
                              {patientsEnrolled}
                            </div>
                          </div>
                        </div>
                        <div className="body-text-size number-cardBottomLabel">
                          Patients Enrolled
                        </div>
                      </div>

                      <div className="numberCard">
                        <div className="number-cardDataContainer">
                          <div className="row">
                            <SymptomIcon className="sub-title-icon number-cardIcon" />
                            <div className="number-cardData big-title-size">
                              {totalSymtpoms || 0}
                            </div>
                          </div>
                        </div>
                        <div className="body-text-size number-cardBottomLabel">
                          Total Symptoms Logged
                        </div>
                      </div>

                      <div className="numberCard">
                        <div className="number-cardDataContainer">
                          <div className="row">
                            <BellIcon className="sub-title-icon number-cardIcon" />
                            <div className="number-cardData big-title-size">
                              0
                            </div>
                          </div>
                        </div>
                        <div className="body-text-size number-cardBottomLabel">
                          Notifications
                        </div>
                      </div>

                      <div className="numberCard">
                        <div className="number-cardDataContainer">
                          <div className="row">
                            <MealsIcon className="sub-title-icon number-cardIcon" />
                            <div className="number-cardData big-title-size">
                              {totalMealsLogged || 0}
                            </div>
                          </div>
                        </div>
                        <div className="body-text-size number-cardBottomLabel">
                          Total Meals Logged
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="studyRightContainer box-rightContainer">
                    {/* <ParentSize>{({ width, height }) => <PatientActivityGraph width={width} height={height} />}</ParentSize>, */}
                    <div className="row edit-row">
                      <div className="sub-title-size sub-title mrb-12">
                        Overall Patient Activity
                      </div>
                      <div className="edit-button">
                        <Tooltip title="Delete">
                          <IconButton onClick={handleDeleteStudyModalOpen}>
                            <DeleteIcon
                              sx={{ width: "30px", height: "30px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="hd-graphEditContainer mrb-12">
                      <div className="hd-graphContainer">
                        <div className="graphTitle">
                          All Patient Activity This Week
                        </div>
                        <PatientActivityGraph data={data} />
                      </div>
                    </div>

                    <div className="studyTrendParent">
                      <div className="sub-title-size sub-title mrb-12 mrt-24">
                        Past Week Trends
                      </div>
                      <div className="stats-container row">
                        <div className="statsCard">
                          <div className="stats-squareStudyContainer">
                            <PatientsIcon className="sub-title-icon" />
                            <div className="body-text-size stats-title">
                              Patients
                            </div>
                          </div>
                          <div className="stats-row row">
                            <div className="big-title-size stats-number">
                              {recentlyActive || 0}
                            </div>
                            {/* <div> Place holder for activity line </div> */}
                          </div>
                          <div className="body-text-size stats-bottomTitle">
                            Recently Active
                          </div>
                        </div>

                        <div className="statsCard">
                          <div className="stats-squareStudyContainer">
                            <MealsIcon className="sub-title-icon" />
                            <div className="body-text-size stats-title">
                              Meals
                            </div>
                          </div>
                          <div className="stats-row row">
                            <div className="big-title-size stats-number">
                              {recentlyLoggedMeals || 0}
                            </div>
                            {/* <div> Place holder for activity line </div> */}
                          </div>
                          <div className="body-text-size stats-bottomTitle">
                            Recently Logged
                          </div>
                        </div>

                        <div className="statsCard">
                          <div className="stats-squareStudyContainer">
                            <SymptomIcon className="sub-title-icon" />
                            <div className="body-text-size stats-title">
                              Symptoms
                            </div>
                          </div>
                          <div className="stats-row row">
                            <div className="big-title-size stats-number">
                              {recentlyLoggedSymptoms || 0}
                            </div>
                            {/* <div> Place holder for activity line </div> */}
                          </div>
                          <div className="body-text-size stats-bottomTitle">
                            Recently Logged
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return <div></div>;
            }
          })}
          <Dialog
            open={deleteStudyModal}
            onClose={handleDeleteStudyModalClose}
            aria-labelledby="Delete Study Pop up"
            aria-describedby="A pop up window to delete an existing study.">
            <DialogTitle>
              {`Are you sure you want to delete this study?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}>
                Study Name: {dialogueTitleDynamic}
              </DialogContentText>
              <DialogContentText
                color="error"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}>
                This action cannot be undone.
              </DialogContentText>
              <DialogContentText
                color="error"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}>
                You will lose all study data that has been collected and will
                not be able to retrieve the study information.
              </DialogContentText>
              <DialogContentText>
                Please type the study name below to confirm deletion
                (case-sensitive):
              </DialogContentText>
            </DialogContent>
            <div className="deleteStudy-buttonGroup">
              <TextField
                id="outlined-basic"
                label="Study Name"
                variant="outlined"
                color="error"
                onChange={handleDeleteStudyTextInput}
              />
              <Button
                onClick={handleSubmitDelete}
                style={{ backgroundColor: "#E53E3E", color: "white" }}>
                Delete
              </Button>
            </div>
            <DialogActions>
              <Button onClick={handleDeleteStudyModalClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <NewStudyModal
            newStudyModalOpen={newStudyModalOpen}
            setNewStudyModalOpen={setNewStudyModalOpen}
            patients={patients}
            setIsLoaded={setIsLoaded}
            handlePostStudy={handlePostStudy}
          />
          <AddPatientModal
            newStudyModalOpen={addPatientModalOpen}
            setNewStudyModalOpen={setAddPatientModalOpen}
            patients={patients}
            setIsLoaded={setIsLoaded}
            handlePostStudy={handlePostStudy}
          />
        </div>
      );
    }
  }

  return <div className="mainContainer">{contentToShow()}</div>;
}

export default DoctorHomeDiary;
