import React from 'react';
import { DataGrid, GridColTypeDef } from '@mui/x-data-grid';
import LoadingIcon from "../../components/common/LoadingIcon";
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as PatientIcon } from "../../../icons/PatientsIconFig.svg";
import GetAppIcon from '@mui/icons-material/GetApp';
import { downloadIndiviudalPatientData } from "../../API/PatientDirectoryCalls";
import { Link } from "react-router-dom";
// import IndividualProfile from "../../../pages/IndividualProfile/IndividualProfile";
import Button from '@mui/material/Button';
import "../../overall.css";
import { Box } from '@mui/material';

type TableProps = {
    data: Object
}




function PatientTable({ data }: TableProps) {
    // const classes = useStyles();

    const [tableRows, setTableRows] = React.useState<Array<Object>>();
    const [dataLoaded, setDataLoaded] = React.useState<Boolean>(true);

    const downloadColumn: GridColTypeDef = {
        type: 'number',
        align: 'center',
        headerAlign: 'left',
        width: 150,
        renderCell: (cellData) => {
            return (
                <IconButton onClick={async () => {
                    setDataLoaded(false);
                    await downloadIndiviudalPatientData(cellData.row.patientNumber, cellData.row.firstLast);
                    setDataLoaded(true);
                }
                } aria-label="delete">
                    <GetAppIcon />
                </IconButton>
            );
        }
    };

    const gotoIndividualProfile: GridColTypeDef = {
        type: 'number',
        align: 'center',
        headerAlign: 'left',
        renderCell: (cellData) =>
            <Button component={Link} to={`/user/profile/${cellData.row.patientNumber}`} style={{ backgroundColor: "rgb(95, 189, 214)", color: "white" }}>
        Profile
      </Button>
    };

    let columns = [
        // { field: 'patientNumber', headerName: 'Patient Number', width: 100 },
        { field: 'firstLast', headerName: 'First Name, Last Name', minWidth: 365, flex:1},
        { field: 'dateEnrolled', headerName: 'Date Enrolled', minWidth: 325},
        { field: 'status', headerName: 'Status', minWidth: 300,},
        { field: "Go to Profile", minWidth: 150, ...gotoIndividualProfile },
        { field: 'Export', minWidth: 150,flex:0.3, ...downloadColumn },
    ];

    React.useEffect(() => {
        var filterPatients: Array<Object> = [];
        Object.values(data).forEach((patient, index) => {
            if ((patient.givenName || patient.surname) && patient.id !== -1) { //-1 signifies they have not logged before
                filterPatients.push(
                    {
                        id: index,
                        patientNumber: patient.patientID,
                        firstLast: patient.givenName + " " + patient.surname,
                        dateEnrolled: patient.startDate,
                        status: "Active Study",
                        authID: patient.azureAuthGUID
                    }
                )
            }
        })

        setTableRows(filterPatients)
    }, [data])
    if (tableRows && dataLoaded) {
        return (
        <Box sx={{ width: "100%", height:'100%', display: 'inline-grid' }}>
            <DataGrid
            rows={tableRows}
            columns={columns}
            paginationModel={{ pageSize: 15, page: 0 }}
            checkboxSelection
            disableRowSelectionOnClick
            autoPageSize={false}
            autosizeOnMount
            autoHeight
            />
        </Box>
        )
    } else {
        return (
            <div className="patientTableLoadingSpinner">
                <LoadingIcon />
                <h2 style={{textAlign: "center", marginRight: "37.5px"}}>One moment, retrieving data...</h2>
            </div>
        );
    }
}

// //ToDO: Use media queries for smaller screens
// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({

//     }),
// );

export default PatientTable;
