import React, { useEffect, useState } from "react";
import TitleBar from "../../components/common/TitleBar";
import { ReactComponent as AllStudies } from "../../img/AllStudies.svg";
import {
  getSignedInUserFirstName,
  getSignedInUserLastName,
} from "../../auth/auth";
import GradientButton from "../../components/common/GradientButton";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  getAllUserStudyInformation,
  joinStudyByCode,
} from "../../API/HomeDiaryCalls";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface Study {
  studyId: string;
  name: string;
  status: string;
  invitationCode: string;
  doctorName: string;
  doctorEmail: string;
  doctorPhone: string;
}

function IndependentUserStudies() {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [joinCode, setJoinCode] = useState<string>("");
  const [studies, setStudies] = useState<Study[]>([]);

  const loadStudies = () => {
    getAllUserStudyInformation().then((result: any[]) => {
      const studies = result?.map(
        (study) =>
          ({
            studyId: study.idStudies,
            name: study.studyName,
            status: study.studyEnrollments.$values[0]?.enrollmentTill
              ? "Enrolled"
              : "Unenrolled",
            invitationCode: study.studyEnrollmentCode,
            doctorName: study.doctorName || "",
            doctorEmail: study.doctorEmail || "",
            doctorPhone: study.doctorPhone || "",
          }) as Study
      );
      setStudies(studies);
    });
  };

  const handleJoin = async () => {
    if (joinCode === "") return;
    await joinStudyByCode(joinCode);
    setJoinCode("");
    loadStudies();
  };

  const handleLeave = async (studyId: string) => {
    // IMPLEMENT
    console.log("Leave study: ", studyId);
  };

  useEffect(() => {
    setFirstName(getSignedInUserFirstName() || "");
    setLastName(getSignedInUserLastName() || "");
    loadStudies();
  }, []);

  return (
    <div className="parentContainer independent-user-studies">
      <div className="mainContainer studyDashboard">
        <TitleBar title={"studies"} subtitle={`${firstName} ${lastName}`} />

        <div className="rounded-[10px] relative flex flex-column gap-6 justify-start w-full">
          <div className="absolute w-full h-fit">
            <div className="sky-blue-bg h-[60px] rounded-[10px] flex z-100 items-center gap-6 justify-between p-4 relative">
              <span className="flex items-center gap-3">
                <AllStudies />
                <h3 className="font-bold text-white">ALL STUDIES</h3>
              </span>
            </div>
          </div>
          <div
            className={`flex justify-between box-container w-full pt-[90px] h-[500px]`}>
            <div>
              <h3 className="font-bold">
                Join a study by adding an invite code:
              </h3>
              <span className="flex justify-between pl-1 pr-1 mt-3">
                <TextField
                  sx={{
                    "width": "120px",
                    "& .MuiInputBase-input": { textAlign: "center" },
                  }}
                  InputProps={{
                    style: {
                      height: "40px",
                    },
                  }}
                  type="text"
                  placeholder="XXXXXX"
                  value={joinCode}
                  onChange={(e) => setJoinCode(e.target.value)}
                />
                <GradientButton
                  onClick={handleJoin}
                  additionalClasses="w-20 h-15">
                  Join
                  <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
                </GradientButton>
              </span>
            </div>
            <div className="w-[70%]">
              {studies ? (
                <>
                  <h3 className="font-bold">Studies:</h3>
                  <div className="mt-3 w-[95%] ">
                    <div className="border border-2 border-sky-300 flex">
                      <div className="w-[50%] p-2">
                        <h3 className="font-bold">Study Name</h3>
                      </div>
                      <div className="w-[50%] pt-2 pr-2 pb-2">
                        <h3 className="font-bold">Status</h3>
                      </div>
                    </div>
                    {studies?.map((study, index) => (
                      <Accordion disableGutters={true}>
                        <AccordionSummary
                          key={index}
                          expandIcon={<ArrowDropDownIcon />}
                          sx={{
                            height: "20px",
                            padding: "0px 0.5rem 0px 0px",
                          }}>
                          <div className="w-full flex">
                            <div className="w-[50%] p-2">
                              <h3>{study.name}</h3>
                            </div>
                            <div className="w-[45%] p-2" onClick={() => {}}>
                              <h3>{study.status}</h3>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex">
                            <h3 className="w-32">Invitation Code:</h3>
                            <h3>{study.invitationCode}</h3>
                          </div>
                          {/* <div className="flex">
                        <h3 className="w-32">Physician:</h3>
                        <h3>{study.doctorName}</h3>
                      </div>
                      <div className="flex">
                        <h3 className="w-32">PI Contact:</h3>
                        <div>
                          <h3>{study.doctorPhone}</h3>
                          <h3>{study.doctorEmail}</h3>
                        </div>
                      </div> */}
                          <div className="w-full flex flex-row-reverse">
                            <GradientButton
                              onClick={() => {
                                handleLeave(study.studyId);
                              }}
                              additionalClasses=" h-15">
                              Leave Study
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                className="text-2xl"
                              />
                            </GradientButton>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </>
              ) : (
                <span>
                  Please join a study by entering your Study code on the left
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndependentUserStudies;
