import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import LoadingIcon from "../../../../components/common/LoadingIcon";
import { roundDecimal } from "../../../../utils/helperFunctions";

type TableProps = {
  data: Array<Object>;
};

function CaloriesTable({ data }: TableProps) {
  const [tableRows, setTableRows] = useState<Array<Object>>();
  const [dataLoaded] = useState<Boolean>(true);

  const columns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "calories", headerName: "Calories", width: 200 },
  ];

  useEffect(() => {
    try {
      const totals = data.map((value: any) => {
        return {
          id: value.$id,
          date: value.consumedDate.split("T")[0],
          calories: roundDecimal(
            value.energyKcal ||
              (value.energyKj > 0 ? value.eneryKj / 4.184 : "0")
          ),
        };
      });

      setTableRows(totals);
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  if (tableRows && dataLoaded) {
    return (
      <DataGrid
        rows={tableRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
      />
    );
  } else {
    return (
      <div className="patientTableLoadingSpinner">
        <LoadingIcon />
        <h2 style={{ textAlign: "center", marginRight: "37.5px" }}>
          One moment, retrieving data...
        </h2>
      </div>
    );
  }
}

export default CaloriesTable;
