import { ResponsiveTreeMap } from '@nivo/treemap'

import severity_data from './SeverityData.json'

const SeverityGraph = ({ data }) => (
    <ResponsiveTreeMap
        data={severity_data}
        identity="name"
        value="value"
        valueFormat=".05s"
        margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
        label="id"
        labelSkipSize={0}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
        parentLabelTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.1 ] ] }}
        animate={true}
        motionStiffness={90}
        motionDamping={11}
    />
)

export default SeverityGraph;