import { BarChart } from "@mui/x-charts/BarChart";

interface Props {
  graphData: any[];
  macroChecks?: any;
}

const MacroBarGraph = ({
  graphData,
  macroChecks = {
    fatsChecked: true,
    carbsChecked: true,
    proteinsChecked: true,
  },
}: Props) => {
  const { fatsChecked, carbsChecked, proteinsChecked } = macroChecks;
  const data = graphData.map((data: any) => {
    const entry: { [key: string]: any } = {
      date: data.date,
    };

    if (carbsChecked) {
      entry.totalCarbs = parseFloat(data.carbohydrates) || 0;
    }

    if (proteinsChecked) {
      entry.totalProteins = parseFloat(data.protein) || 0;
    }

    if (fatsChecked) {
      entry.totalFats = parseFloat(data.fats) || 0;
    }

    return entry;
  });

  const series: { dataKey: string; label: string; color: string }[] = [];
  if (carbsChecked) {
    series.push({ dataKey: "totalCarbs", label: "Carbs", color: "#FF9029" });
  }
  if (proteinsChecked) {
    series.push({
      dataKey: "totalProteins",
      label: "Proteins",
      color: "#F4B6B6",
    });
  }
  if (fatsChecked) {
    series.push({ dataKey: "totalFats", label: "Fats", color: "#F4BE6D" });
  }

  return (
    <BarChart
      dataset={data}
      // height={500}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "date",
        },
      ]}
      series={series}
    />
  );
};

export default MacroBarGraph;
