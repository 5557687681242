import React from "react";
import FormControl from "@mui/material/FormControl";
import { Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

interface Study {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<any>;
  };
  studyID: Number;
}

type Props = {
  study: Study;
  studies: Study[];
  setStudy: React.Dispatch<React.SetStateAction<Study>>;
  studyIndex: number;
  setStudyIndex: React.Dispatch<React.SetStateAction<number>>;
  additionalClasses?: string;
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    "borderRadius": 4,
    "position": "relative",
    "backgroundColor": theme.palette.background.paper,
    "border": "none",
    "fontSize": 20,
    "fontWeight": 700,
    "padding": "10px 26px 10px 12px",
    "color": "#0195bc",
    "transition": theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "fontFamily": [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "&.Mui-focused .MuiInputBase-input": {
    backgroundColor: "#e0f7fa", // Change this color as needed
    color: "initial",
  },
}));

function DashBoardDropDown({
  study,
  studies,
  setStudy,
  setStudyIndex,
  studyIndex,
  additionalClasses = "",
}: Props) {
  const handleChange = (event: { target: { value: string } }) => {
    setStudy(
      studies.find((study) => study.studyID.toString() === event.target.value)!
    );
    setStudyIndex(
      studies.findIndex(
        (study) => study.studyID.toString() === event.target.value
      )
    );
  };

  // useEffect(() => {
  //   if (studies.length > 0) {
  //     setStudy(studies[studyIndex].name);
  //   }
  // });

  return (
    <div className={`dashboard-dropdown ${additionalClasses}`}>
      <FormControl sx={{ minWidth: 120 }} size="small">
        <Select
          labelId="study-select"
          id="demo-customized-select"
          value={study?.studyID.toString()}
          onChange={handleChange}
          input={<BootstrapInput />}>
          {studies.map((studyItem) => {
            return (
              <MenuItem
                key={studyItem.studyID.toString()}
                value={studyItem.studyID.toString()}>
                {studyItem.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default DashBoardDropDown;
