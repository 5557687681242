import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const MacroCheckBox = ({ macroChecks, onChangeFunction }) => {
  const { fatsChecked, carbsChecked, proteinsChecked } = macroChecks;
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={fatsChecked}
            onChange={onChangeFunction}
            name="fatsChecked"
          />
        }
        label="Fats"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={carbsChecked}
            onChange={onChangeFunction}
            name="carbsChecked"
          />
        }
        label="Carbs"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={proteinsChecked}
            onChange={onChangeFunction}
            name="proteinsChecked"
          />
        }
        label="Proteins"
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={caloriesChecked}
            onChange={onChangeFunction}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="caloriesChecked"
          />
        }
        label="Calories"
      /> */}
    </FormGroup>
  );
};

export default MacroCheckBox;
