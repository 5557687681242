import { Component, ChangeEvent } from "react";
import VisualizationDropDown from "../VisualizationDropDown";
import GenderDropDown from "../GenderDropDown";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { getSignedInUserId } from "../../../../auth/auth";
import { FetchInfo } from "../../../../components/common/types/FetchInfo";
import GraphTypeDropDown from "../GraphTypeDropDown";
import { FaDownload } from "react-icons/fa";

interface GraphDisplayProps {
  isStudyDash: boolean;
  api_data: any[]; // Add this line
  setData: (data: any) => void;
  setFetchInfo: (info: FetchInfo) => void;
  setVisualization: (visualization: string) => void;
  handleDownloadData: () => void;
  handleLoadData: (userId: string) => void;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  handleGraphTypeChange: (value: string) => void;
  handleVisualizationChange: (value: string) => void;
  graphType: string;
  visualization: string;
  startDate: Date;
  endDate: Date;
}

interface GraphDisplayState {
  visualization: string;
  graphType: string;
  vitamin: string;
  macro: string;
  fatsChecked: boolean;
  carbsChecked: boolean;
  proteinsChecked: boolean;
  caloriesChecked: boolean;
  gender: string;
  minAge: number;
  maxAge: number;
  selectedDateOne: Date;
  selectedDateTwo: Date;
  isGraphDataLoading: boolean;
  isGraphButtonClicked: boolean;
  api_data: any | null;
  vitaminData: any | null;
  mineralData: any | null;
  loggingData: any | null;
  macroData: any | null;
  severityData: any | null;
  isStudyDash: boolean;
}

class StudyGraphDisplay extends Component<
  GraphDisplayProps,
  GraphDisplayState
> {
  constructor(props: GraphDisplayProps) {
    super(props);

    this.state = {
      visualization: "macronutrients",
      graphType: "table",
      vitamin: "",
      macro: "",
      fatsChecked: true,
      carbsChecked: true,
      proteinsChecked: true,
      caloriesChecked: true,
      gender: "",
      minAge: 0,
      maxAge: 120,
      selectedDateOne: new Date(),
      selectedDateTwo: new Date(),
      isGraphDataLoading: false,
      isGraphButtonClicked: false,
      api_data: null,
      vitaminData: null,
      mineralData: null,
      loggingData: null,
      macroData: null,
      severityData: null,
      isStudyDash: props.isStudyDash,
    };
  }

  componentDidMount() {
    //console.log(this.props.isStudyDash);
  }

  componentDidUpdate() {
    // console.log(this.props.isStudyDash);
  }

  componentWillUnmount() {}

  onChangeVisualization = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "micronutrients") {
      this.props.handleGraphTypeChange("table");
    }
    this.props.handleVisualizationChange(e.target.value);
  };

  onChangeGraphType = (e: ChangeEvent<HTMLSelectElement>) => {
    this.props.handleGraphTypeChange(e.target.value);
  };

  onChangeVitamin = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ vitamin: e.target.value });
  };

  onChangeGender = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ gender: e.target.value });
  };

  onChangeAgeOne = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) } as any);
  };

  onChangeAgeTwo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) } as any);
  };

  handleDateOneChange = (date: Date | null) => {
    if (date) {
      this.props.handleStartDateChange(date);
    }
  };

  handleDateTwoChange = (date: Date | null) => {
    if (date) {
      this.props.handleEndDateChange(date);
    }
  };

  getUserId = async () => {
    let userID = parseInt(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
    if (isNaN(userID)) {
      var userIDfromcall = await getSignedInUserId();
      if (userIDfromcall === null) {
        console.error("Failed to fetch user ID");
        alert("Failed to fetch the user ID. Please try again.");
        return;
      } else {
        userID = userIDfromcall;
      }
    }
    return userID;
  };

  getFechInfo = async (): Promise<FetchInfo | undefined> => {
    let userId = await this.getUserId();
    if (!userId) {
      return;
    }
    return {
      visualization: this.props.visualization,
      graphType: this.props.graphType,
      gender: this.state.gender,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedDateOne: this.props.startDate,
      selectedDateTwo: this.props.endDate,
      userID: userId.toString(),
    };
  };

  handleSubmitDataClick = async () => {
    let userID = await this.getUserId();
    if (!userID) {
      return;
    }
    this.props.handleLoadData(userID.toString());

    this.props.setData([]);
    this.setState({
      isGraphDataLoading: true,
      isGraphButtonClicked: true,
    });
    const fetchInfo = await this.getFechInfo();
    if (!fetchInfo) {
      return;
    }
    this.props.setFetchInfo(fetchInfo);
  };

  handleExportDataClick = () => {
    this.props.setData([]);
    this.setState({
      isGraphDataLoading: true,
      isGraphButtonClicked: true,
    });

    let userID = parseInt(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
    if (isNaN(userID)) {
      console.error("Failed to fetch user ID");
      alert("Failed to fetch the user ID. Please try again.");
      return;
    }

    const fetchInfo: FetchInfo = {
      visualization: this.state.visualization,
      graphType: this.state.graphType,
      gender: this.state.gender,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedDateOne: this.state.selectedDateOne,
      selectedDateTwo: this.state.selectedDateTwo,
      userID: userID.toString(),
      export: true,
    };
    this.props.setFetchInfo(fetchInfo);
  };

  renderDisplay() {
    // Placeholder for future content
    return null;
  }

  renderAgeGender() {
    if (this.state.isStudyDash) {
      return (
        <div>
          <label>Gender:</label>
          <GenderDropDown onChangeFunction={this.onChangeGender} />
          <label>Age Range:</label>
          <div className="row">
            <input
              className="age-input"
              onChange={this.onChangeAgeOne}
              type="number"
              id="min-age"
              name="minAge"
            />
            <p className="separate">to</p>
            <input
              className="age-input"
              onChange={this.onChangeAgeTwo}
              type="number"
              id="max-age"
              name="maxAge"
            />
          </div>
        </div>
      );
    }
    return <div></div>;
  }

  render() {
    return (
      <div className="box-container study-graph-display">
        <form className="formContainer">
          <div className="card-title-text">Data group</div>
          <div className="pr-2 pl-2 pt-1">
            <div className="body-text body-text-size">Data Type:</div>
            <VisualizationDropDown
              value={this.props.visualization}
              onChangeFunction={this.onChangeVisualization}
              options={["macronutrients", "micronutrients", "calories"]}
            />

            <div className="body-text body-text-size mt-1">
              Data Visualization:
            </div>
            <GraphTypeDropDown
              value={this.props.graphType}
              visualization={this.props.visualization}
              disabled={this.props.visualization === "micronutrients"}
              onChangeFunction={this.onChangeGraphType}
              options={[
                { value: "table", text: "Table" },
                { value: "line", text: "Line Chart" },
                { value: "bar", text: "Bar Chart" },
              ]}
            />
            <div>
              {this.renderAgeGender()}
              <div className="body-text body-text-size mt-1 mb-1">
                Date Range:
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    format="MM/dd/yyyy"
                    views={["year", "month", "day"]}
                    onChange={this.handleDateOneChange}
                    defaultValue={new Date()}
                  />
                  <p className="separate-date body-text body-text-size">to</p>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    format="MM/dd/yyyy"
                    views={["year", "month", "day"]}
                    onChange={this.handleDateTwoChange}
                    defaultValue={new Date()}
                  />
                </LocalizationProvider>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="mt-4">
                <Box
                  sx={{
                    "display": "flex",
                    "& > button": {
                      "backgroundColor": "#5fbdd6",
                      "color": "white",
                      "&:hover": {
                        color: "#5fbdd6",
                      },
                      "borderColor": "white",
                      "borderRadius": "10px",
                    },
                  }}>
                  <LoadingButton
                    sx={{ "width:": "50px" }}
                    onClick={this.handleSubmitDataClick}
                    loading={false}
                    variant="outlined">
                    Load Table
                  </LoadingButton>
                  <LoadingButton
                    onClick={this.props.handleDownloadData}
                    loading={false}
                    variant="outlined">
                    <FaDownload />
                  </LoadingButton>
                </Box>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default StudyGraphDisplay;
