import "./GradientButton.css";

interface Props {
  text?: string;
  onClick: () => void;
  additionalClasses?: string;
  children?: React.ReactNode;
}

const GradientButton = ({
  text,
  onClick,
  additionalClasses,
  children,
}: Props) => {
  return (
    <div onClick={onClick} className={`gradient-button ${additionalClasses}`}>
      <span className="flex items-center gap-2">
        {text} {children ? children : null}
      </span>
    </div>
  );
};

export default GradientButton;
