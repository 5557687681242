import React from "react";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import { Select, MenuItem } from "@mui/material";
import "../GraphDisplay.css";

const BootstrapSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    "position": "relative",
    "backgroundColor": "#FAFAFA",
    "color": "#5FBDD6",
    "border": "1px solid #5FBDD6",
    "fontSize": 16,
    "fontWeight": "bold",
    "transition": theme.transitions.create(["border-color", "box-shadow"]),
    "fontFamily": [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 6,
      borderColor: "#5FBDD6",
    },
  },
}));

const FormControlStyled = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const GraphTypeDropDown = ({
  options,
  disabled,
  onChangeFunction,
  value,
  visualization,
}) => {
  return (
    <FormControlStyled style={{ minWidth: 170, width: "90%" }}>
      <BootstrapSelect
        defaultValue={value}
        value={value}
        onChange={onChangeFunction}
        size="small"
        disabled={disabled}
        sx={{ marginLeft: -1, marginTop: 0, marginBottom: 0 }}
        fullWidth>
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>
          );
        })}
      </BootstrapSelect>
    </FormControlStyled>
  );
};

export default GraphTypeDropDown;
