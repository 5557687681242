import { Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";

const MobileWarningModal = () => {
  const [showMobileWarning, setShowMobileWarning] = useState<boolean>(false);

  useEffect(() => {
    if (!sessionStorage.getItem("mobileWarningShown")) {
      setShowMobileWarning(true);
    }
  }, [setShowMobileWarning]);

  const handleCloseModal = () => {
    setShowMobileWarning(false);
    sessionStorage.setItem("mobileWarningShown", "true");
  };

  return (
    <Modal
      open={showMobileWarning}
      onClose={handleCloseModal}
      aria-labelledby="mobile-warning"
      aria-describedby="mobile-warning-description">
      <div className=" leading-relaxed absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-4/5 max-w-xs outline-none flex flex-column align-center">
        <h1 id="title" className="pb-4 font-bold text-xl text-center">
          Sorry!
        </h1>
        <p>This website is not optimized for mobile devices yet.</p>
        <p>Please use a desktop or laptop for the best experience.</p>
        <p className="pb-4">Mobile version coming soon!</p>
        <Button
          variant="contained"
          className="text-white self-center"
          onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default MobileWarningModal;
