import "../../overall.css";
import "./TitleBar.css";
import { signOut } from "../../auth/auth";

import { Button } from "@mui/material";

type TitleProps = {
  title: String;
  subtitle?: String;
  studyDropdown?: React.ReactNode;
};

function TitleBar({ title, subtitle, studyDropdown }: TitleProps) {
  return (
    <div className="title-bar box-container">
      <div className="appBar">
        <div className="toolBarContainer row">
          <div className="title-text-wrapper">
            <h3 className="title">{title}</h3>
            <span className="sub-title flex space-between gap-4 items-center">
              <>{studyDropdown ? studyDropdown : subtitle}</>
            </span>
          </div>
          <div className="toolBarIconContainer">
            <div className="sign-out-container">
              <Button
                onClick={signOut}
                variant="contained"
                sx={{
                  color: "white",
                  background: "#0195BC",
                  textTransform: "none",
                }}>
                Sign Out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleBar;
