import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as MobileIcon } from "../../../../icons/MobileIconFig.svg";
import { ReactComponent as MealIcon } from "../../../../icons/MealsIconFig.svg";
import { ReactComponent as SymptomIcon } from "../../../../icons/SymptomsIconFig.svg";
import { getAccessToken } from '../../../../auth/auth';
import { fetchData, initFetchRequest } from '../../../../utils/helperFunctions';
import LoadingIcon from '../../../../components/common/LoadingIcon';
import { DownloadAppModal } from '../Modals/DownloadAppModal';
import AppleStoreIcon from '../../../../img/download-on-the-app-store-apple-logo-svgrepo-com.svg';
import GooglePlayIcon from '../../../../img/google-play-badge-logo-svgrepo-com.svg';
import '../Modals/DownloadAppModal.css'; // Ensure this file is correctly imported

interface PatientInformationCardProps {
    patientID: string | null;
}

function PatientInformationCard({ patientID }: PatientInformationCardProps)  {

    const [data, setData] = useState<any>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [totalMealsLogged, setTotalMealsLogged] = useState<number|null>(null);
    const [totalSymptomsLogged, setTotalSymptomsLogged] = useState<number|null>(null);
    const [mobileLogging, setMobileLogging] = useState<number|null>(null);
     //Getting Stored Auth ID & Patient ID
     //const { authID, userID } = React.useContext(AuthContext);


     const getProfileOverview = useCallback(async () => {
        setDataLoaded(false);
        const token = await getAccessToken();
        //const patientId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        //If the patientID is not empty, then the user is a admin and the patientID is passed in the URL and the data is fetched for that patient
        //const patientId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        
        //What was workin before in the individual user view
        //const apiEndpoint = userId !== "" ? "/api/Patients/ProfileOverview/" + userId : "/api/Patients/UserProfileOverview";
        const apiEndpoint = patientID !== null ? "/api/Patients/ProfileOverview/" + patientID : "/api/Patients/UserProfileOverview";
        const request = initFetchRequest(apiEndpoint, token);
        const response = await fetchData(request);
        setData(response);
        setTotalMealsLogged(response.totalMealsLogged);
        setTotalSymptomsLogged(response.totalSymptomsLogged);
        setMobileLogging(response.totalSymptomsLogged + response.totalMealsLogged);
        setDataLoaded(true);
    }, [patientID]);

    useEffect(() => {
        getProfileOverview();
    }, [getProfileOverview]);

    
  // Use an effect to check if the modal should be shown after data is set
    useEffect(() => {
        if (dataLoaded && (totalSymptomsLogged !== null && totalMealsLogged !== null) && (mobileLogging) === 0) {
               
                setShowModal(true);
                //console.log("Show modal", showModal);
        }
}, [dataLoaded, totalSymptomsLogged, totalMealsLogged, mobileLogging]); // Depend on dataLoaded and data to ensure it checks after these are set


 
     const handleCloseModal = () => {
            //console.log("Close modal");
            setShowModal(false);
        };
    

    return (
        <div className="si-mainContainer box-container">
            {dataLoaded === false
                ? (
                    <div className="small-loadingContainer">
                        <LoadingIcon />
                    </div>
                )
                : (
                    <>{/* Modal for prompting app download */ } 
                        <DownloadAppModal show={showModal} onClose={handleCloseModal}>
                            <p>Please download our mobile app to start logging data and then view it on the website dashboard.</p>
                                <div className="button-container">
                                    <a href="https://apps.apple.com/us/app/mymeddiary/id1506742164">
                                        <img src={AppleStoreIcon} alt="Download on the App Store" className="img-small"/>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.ComputingReApplied.myMedDiary&hl=en_US">
                                        <img src={GooglePlayIcon} alt="Get it on Google Play" className="img-small" />
                                    </a>
                                </div>
                        </DownloadAppModal>
                        <div className="row edit-row">
                            <div className="sub-title-size sub-title">Patient Information</div>
                            <div className="edit-button">
                                <button className="text-button">Edit</button>
                            </div>
                        </div>
                        <div className="row icon-text-row">
                            <MealIcon className="sub-title-icon" />
                            <div className="title-size stats-title icon-text-stats">{data.totalMealsLogged}</div>
                            <div className="body-text-size body-text icon-text-text">Total Meals Logged</div>
                        </div>
                        <div className="row icon-text-row">
                            <SymptomIcon className="sub-title-icon" />
                            <div className="title-size stats-title icon-text-stats">{data.totalSymptomsLogged}</div>
                            <div className="body-text-size body-text icon-text-text">Total Symptoms Logged</div>
                        </div>
                        <div className="row icon-text-row">
                            <MobileIcon className="sub-title-icon" />
                            <div className="title-size stats-title icon-text-stats">{data.totalSymptomsLogged + data.totalMealsLogged}</div>
                            <div className="body-text-size body-text icon-text-text">Mobile Logging</div>
                        </div>
                    </>
                )
            }

        </div>
    );
}

export default PatientInformationCard;