import React, { useEffect, useState } from "react";
// import { useState,  } from 'react';
import PatientInformationCard from "./components/IndividualProfile/PatientInformationCard";
import IndividualPatientOverviewCard from "./components/IndividualProfile/IndividualPatientOverviewCard";
import PatientGraphComponent from "./components/IndividualProfile/ProfileGraphComponent";
import TitleBar from "../../components/common/TitleBar";
import "./Dashboard.css";
import "../../overall.css";
import {
  getSignedInUserFirstName,
  getSignedInUserLastName,
  getSignedInUserUsername,
} from "../../auth/auth";
//import { getAccessToken } from '../../auth/auth'
//import LoadingIcon from '../../components/common/LoadingIcon'
//import { fetchData, initFetchRequest } from '../../utils/helperFunctions'
//import { constant, forEach } from 'lodash'

// import GraphDisplay from "./GraphDisplay"
/*type IndividualPatientProps = {
    patients: AzureUserInfo[];
}
type AzureUserInfo = {
    displayName: string,
    givenName : string ,
    jobTitle? : string | null,
    mail? : string | null,
    mobilePhone?: string | null,
    officeLocation?: string | null,
    preferredLanguage?: string | null,
    surname: string ,
    userPrincipalName: string,
    patientID: string,
    id: string,
    BusinessPhones? : string[] | null,
} 
interface LocationState {
    patientAuthID: string;
    patientID: string;
    firstName: string;
    lastName: string;
    username: string;
  }*/

function IndependentUserProfileDashboard() {
  // const [data, setData] = useState<AzureUserInfo>();
  const [firstName, setFirstName] = useState<string | null>();
  const [surname, setSurname] = useState<string | null>();
  const [username, setUsername] = useState<string | null>();
  // const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchedFirstName = getSignedInUserFirstName();
    const fetchedSurname = getSignedInUserLastName();
    const fetchedUsername = getSignedInUserUsername();

    setFirstName(fetchedFirstName);
    setSurname(fetchedSurname);
    setUsername(fetchedUsername);
  }, [firstName, surname, username]);

  /*const getUserData = async () => {
        setDataLoaded(false);
        var token = await getAccessToken();
        const request = initFetchRequest(
            "/api/Users/GetUserAttributes/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
            token
        );
        
        const response = await fetchData(request);
        console.log(response)
        setData(response)
        setDataLoaded(true);
    }*/

  return (
    <div className="parentContainer">
      <div className="mainContainer studyDashboard">
        {" "}
        {/* Rule in app.css */}
        {/*dataLoaded === false
                    ? (<div className="small-loadingContainer">
                        <LoadingIcon />
                    </div>
                    )
                    :
                    (
                        <>
                            <TitleBar currentPage={[false, false, false, true]} firstName={firstName} lastName={surname} userName={username} ></TitleBar>
                        </>
                    )
                */}
        <>
          <TitleBar title={"myDashboard"} />
        </>
        <div className="cardParentContainer">
          <div className="studyInformation row">
            <PatientInformationCard patientID={null} />
            <IndividualPatientOverviewCard patientID={null} />
          </div>
          {
            <PatientGraphComponent
            // graphToDisplay={visualization}
            // macroChecks={macroChecks}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default IndependentUserProfileDashboard;
