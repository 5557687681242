import { API_PREFIX } from "./globalConstants";

// import { first } from "lodash";

/**
 * Capitalizes and returns the parameter string.
 *
 * @param {string} string   An input string.
 * @return {string}         The capitalized string.
 */
export const capitalize = (string) => {
  if (string !== null && string !== undefined) {
    let split_str = string
      .split(" ")
      .map(
        (char) =>
          char.charAt(0).toUpperCase() + char.slice(1).toLocaleLowerCase()
      );
    let result = "";
    split_str.forEach((str) => (result += str + " "));
    result = result.trim();
    //return string.charAt(0).toUpperCase() + string.slice(1);
    return result;
  }
  return string;
};

export const initFetchRequest = (url, accessToken) => {
  const myRequest = new Request(`${API_PREFIX}${url}`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + accessToken,
      //'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Allow-Origin": "https://portal.computingreapplied.com/",
      // 'Access-Control-Allow-Origin': 'https://localhost:5001/', //we keep this as localhost for testing, will switch out with computingreapplied.com at producyion
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      //'Access-Control-Allow-Headers': 'Origin, X- Requested - With, Content - Type, Accept',
    },
  });
  return myRequest;
};

export const initPostRequest = (url, accessToken, requestBody) => {
  const myRequest = new Request(`${API_PREFIX}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      //'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Allow-Origin": "https://portal.computingreapplied.com/",
      // 'Access-Control-Allow-Origin': 'https://localhost:5001/', //we keep this as localhost for testing, will switch out with computingreapplied.com at producyion
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      //'Access-Control-Allow-Headers': 'Origin, X- Requested - With, Content - Type, Accept',
    },
    body: requestBody,
  });
  return myRequest;
};

export const initDeleteRequest = (url, accessToken) => {
  const myRequest = new Request(`${API_PREFIX}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken,
      //'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Allow-Origin": "https://portal.computingreapplied.com/",
      // 'Access-Control-Allow-Origin': 'https://localhost:5001/', //we keep this as localhost for testing, will switch out with computingreapplied.com at producyion
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      //'Access-Control-Allow-Headers': 'Origin, X- Requested - With, Content - Type, Accept',
    },
  });
  return myRequest;
};

export const fetchData = async (myRequest) => {
  return fetch(myRequest)
    .then((response) => {
      if (response.status === 204) {
        return "Study Deleted";
      }
      let dataJSON = response.json();
      return dataJSON;
    })
    .then((data) => {
      return data;
    })
    .catch((jqXHR, textStatus) => {
      console.log("Error calling the Web api:\n" + textStatus + "\n" + jqXHR);
    });
};

// this might be phased out
export const addLocalTimeStr = (data, type) => {
  let result = data;
  result.fullTimeStr = "";
  if (type === "symptom") {
    result.fullEntryTimeStr = new Date(
      `${result.symptoms[0].symptomTime}Z`
    ).toString();
  } else if (type === "meal") {
    result.fullEntryTimeStr = new Date(`${result.timeConsumed}Z`).toString();
  }

  return result;
};

/**
 * Formats the input date into the correct format as seen on the patient
 * profile cards. i.e. 'August 07, 2020 3:36 PM'
 *
 * @param {Date} date       The date that needs to be formatted.
 * @param {Object} months   Every month in the year.
 * @return {string}         String representation of the formatted input date.
 */
export function formatTime(date, monthz) {
  let months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  let hrs = date.getHours();
  let min = date.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  let timeSuffix = "AM";
  if (hrs >= 12) {
    if (hrs !== 24) {
      timeSuffix = "PM";
    }
    if (hrs > 12) {
      hrs -= 12;
    }
  }
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1); //January is 0!
  let month = months[mm];
  let yyyy = date.getFullYear();
  return (
    month + " " + dd + ", " + yyyy + " " + hrs + ":" + min + " " + timeSuffix
  );
}

export const roundDecimal = (decimalString) => {
  decimalString = String(decimalString);
  if (String(decimalString).includes(":")) {
    return decimalString;
  }
  const roundedNumber = parseFloat(decimalString).toFixed(2);
  if (decimalString.endsWith("g")) {
    return roundedNumber + "g";
  }
  return roundedNumber;
};

export const formatGraphData = (graphData) => {
  const formattedData = graphData.map((value) => {
    return {
      date: convertDate(value.consumedDate),
      calories: roundDecimal(
        value.energyKcal || (value.energyKj > 0 ? value.eneryKj / 4.184 : "0")
      ),
      protein: roundDecimal(value.proteinG || "0"),
      fats: roundDecimal(value.totalLipidFatG || "0"),
      carbohydrates: roundDecimal(value.carbohydrateByDifferenceG || "0"),
    };
  });
  return formattedData;
};

// Convert UTC date to current time zone
export const convertDate = (date, noTimeZone) => {
  const userDate = new Date(date.split("T")[0]);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  if (!noTimeZone) {
    options.timeZoneName = "short";
  }
  return userDate.toLocaleDateString("en-US", options);
};
