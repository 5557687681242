import React, { useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';

import { DataGrid } from '@mui/x-data-grid';
import LoadingIcon from "../../../../components/common/LoadingIcon";

//CSS
import "../../DoctorHomeDiary.css";
import '../../../../overall.css';

// API Import
import { postNewStudy } from '../../../../API/HomeDiaryCalls';

const steps = ['Study Information', 'Customizable Features', 'Add Patients', 'Create Study'];
const steps_desc = ['Enter Study Information', 'Select Customizable Features', 'Select Patients', 'Review Study Summary']



const StyledOrderedList = styled('ol')(({ theme }) => ({
  listStyleType: 'decimal',
  paddingLeft: theme.spacing(4),
}));

const StyledListItem = styled('li')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

//TODO: Matt -> Refactor study container and break up into components
function NewStudyModal(props) {
    const [activeStep, setActiveStep] = useState(0);

    // Form data
    const [studyName, setStudyName] = useState("");
    const [studyNameError, setStudyNameError] = useState(false);
    const [studyNameHelp, setStudyNameHelp] = useState("");
    const [studyType, setStudyType] = useState('Single');
    const [studyDescription, setStudyDescription] = useState("");
    //Table states/variables
    const [tableRows, setTableRows] = useState([]);
    const [tableSelectedIDs, setTableSelectedIDs] = useState(new Set());
    let columns = [
        // { field: 'patientNumber', headerName: 'Patient Number', width: 100 },
        { field: 'firstLast', headerName: 'First Name, Last Name', width: 350 },
    ];

    useEffect(() => {
        var filterPatients = [];
        Object.values(props.patients).forEach((patient, index) => {
            if ((patient.givenName || patient.surname) && patient.patientID !== -1) { //note: patients with -1 id break server
                filterPatients.push(
                    {
                        id: index,
                        patientNumber: patient.patientID,
                        firstLast: patient.givenName + " " + patient.surname,
                        dateEnrolled: patient.startDate,
                        status: "Active Study",
                        authID: patient.azureAuthGUID
                    }
                )
            }
        })

        setTableRows(filterPatients)
    }, [props.patients, tableSelectedIDs])


    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handleStudyNameChange = (event) => {
        if (studyNameError) {
            setStudyNameError(false);
            setStudyNameHelp("");
        }
        setStudyName(event.target.value);
    }
    const handleStudyTypeChange = (event) => {
        setStudyType(event.target.value);
    };
    const handleStudyDescriptionChange = (event) => {
        setStudyDescription(event.target.value);
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (studyName === "") {
                setStudyNameError(true);
                setStudyNameHelp("Required Field")
                return;
            }
        } else if (activeStep === 3) {
            createStudy();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const clearPreviousStudy = () => {
        setStudyName("")
        setStudyNameError(false);
        setStudyNameHelp("")
        setStudyType('Single');
        setStudyDescription("");
        setActiveStep(0);
    }


    function showPatients() {
        var idSet = new Set(tableSelectedIDs);
        var listPatients = [];
        tableRows.forEach(value => {
            if (idSet.has(value.id)) {
                listPatients.push(value.firstLast);
            }
        })
        // setSelectedPatients(savePatients);
        return (
            listPatients.map((value, index) => {
                return (<StyledListItem className="body-text-size dark-grey">{value}</StyledListItem>)
            })
        )
    }

    async function createStudy() {
        var idSet = new Set(tableSelectedIDs);
        var listPatients = [];
        tableRows.forEach(value => {
            if (idSet.has(value.id)) {
                listPatients.push(
                    {
                        patientID: value.patientNumber,
                        startDate: "2021-12-14T08:31:00.057Z",
                        endDate: new Date().toISOString(),
                    }
                );
            }
        })
        await postNewStudy(0, studyName, studyDescription, listPatients);
        window.location.reload();
        let study = {
            $id: "-1",
            abstract: studyDescription,
            studyID: 0,
            patients: listPatients,
            name: studyName
        }
        window.location.reload();
        props.handlePostStudy(study);
        props.setNewStudyModalOpen(false);
        props.setIsLoaded(true)
    }

    function modalContent() {
        if (activeStep === 0) {
            return (
                <div>

                    <TextField
                        required
                        id="outlined-study-description"
                        label="Study Name"
                        onChange={handleStudyNameChange}
                        defaultValue={studyName}
                        error={studyNameError}
                        helperText={studyNameHelp}
                        style ={{width: '243px'}}
                    />

                    <span className="mrl-12"></span>

                    <FormControl>
                        <InputLabel id="study-type-select-label">Study Type</InputLabel>
                        <Select
                            labelId="study-type-select-label"
                            id="study-type-select"
                            value={studyType}
                            label="Study Type"
                            onChange={handleStudyTypeChange}
                            defaultValue={studyType}
                            style ={{width: '243px'}}
                        >
                            <MenuItem value={"Single"} label="Study Name">Single</MenuItem>
                            <MenuItem value={"Double"} label="Study Name">Double</MenuItem>
                        </Select>
                    </FormControl>


                    <div className="mrt-12">
                        <TextField
                            id="outlined-study-description"
                            label="Study Description"
                            multiline
                            rows={12}
                            onChange={handleStudyDescriptionChange}
                            defaultValue={studyDescription}
                            style={{width: "500px"}}
                        />
                    </div>
                </div>
            )
        } else if (activeStep === 1) {
            return (
                <div className="nsModal-container">
                    <div>Select Customizable Features: (Disabled) </div>
                    <div className="nsModal-customFeaturesContainer">
                        <div className="nsModal-checkboxRow">
                            <div>Symptom Logging</div>
                            <Checkbox {...label} disabled />
                        </div>
                        <div className="nsModal-checkboxRow">
                            <div>Cold/Flu</div>
                            <Checkbox {...label} disabled />
                        </div>
                        <div className="nsModal-checkboxRow">
                            <div>Pain</div>
                            <Checkbox {...label} disabled />
                        </div>
                        <div className="nsModal-checkboxRow">
                            <div>Stomach Bug</div>
                            <Checkbox {...label} disabled />
                        </div>
                        <div>Add Other: </div>
                        <div className="nsModal-checkboxRow">
                            <div>Food Logging</div>
                            <Checkbox {...label} disabled />
                        </div>
                        <div className="nsModal-checkboxRow">
                            <div>Water Intake Tracking</div>
                            <Checkbox {...label} disabled />
                        </div>
                        <div className="nsModal-checkboxRow">
                            <div>Period Irregularity Tracking</div>
                            <Checkbox {...label} disabled />
                        </div>
                    </div>
                </div>
            )
        } else if (activeStep === 2){
            if (tableRows !== undefined) {
                return (
                    <div style={{ height: "100%", width: '100%' }}>
                        <DataGrid
                            // sx={{
                            //     '.MuiCheckbox-colorPrimary.Mui-checked': {
                            //         color: 'red'
                            //     }
                            // }}
                            rows={tableRows}
                            columns={columns}
                            checkboxSelection
                            pageSize={1}
                            autoHeight
                            // autoPageSize={true}
                            selectionModel={tableSelectedIDs}
                            onRowSelectionModelChange={(ids) => {
                                setTableSelectedIDs(ids);
                                // const tempArray = [];
                                // const selectionModel = [];
                                // tableRows.forEach(element => {
                                //     if (element.id !== undefined) {
                                //         if (selectedIDs.has(element.id)) {
                                //             tempArray.push(element);
                                //             selectionModel.push(element.id);
                                //         }
                                //     }
                                // });
                                // setSelectedPatients(tempArray);
                                // setTableSelectionModel(selectionModel);
                            }}
                        />
                    </div>
                )
            } else {
                return (
                    <div style={{ height: "100%", width: '100%' }}>
                        <LoadingIcon style={{ marginLeft: "15%" }} />
                    </div>
                )
            }
        } else {
            return (
                <div className={"nsModal-cs-summaryContainer"}>
                    <div className="body-text-size primary-blue mrb-8">Study Name: 
                        <span className="body-text-size dark-grey ps-1">{studyName}</span>
                    </div>
                    <div className="body-text-size primary-blue mrb-8">Study Type:
                        <span className="body-text-size dark-grey ps-1">{studyType}</span>
                    </div>
                    <div className="body-text-size primary-blue mrb-8">Total Number of Patients: 
                        <span className="body-text-size dark-grey ps-1">{tableSelectedIDs.length}</span>
                    </div>
                    <div className="body-text-size primary-blue mrb-8">Patients: 
                        <StyledOrderedList>
                            {showPatients()}
                        </StyledOrderedList>
                    </div>
                </div>
            )
        }
    }

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const handleNewStudyClose = () => {
        props.setNewStudyModalOpen(false);
    };

    const label = { inputProps: { 'aria-label': 'Checkbox' } };

    const body = (
        <div style={modalStyle} className="newStudyModal box-container">

            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <div className="nsModal-content">
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <div className="dark-grey body-text-size">
                            All steps completed - you&apos;re finished
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Box>
                        <Button onClick={clearPreviousStudy} style={{ backgroundColor: "rgb(95, 189, 214)", color: "white" }}>Create Another Study</Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        
                        <div className="row">
                            <div className="sub-title-size sub-title mrb-12 mrt-24">Step {activeStep + 1}</div>
                            <div className="sub-title-size body-text mrb-12 mrt-24 mrl-12">{steps_desc[activeStep]}</div>
                        </div>
                    
                        {modalContent()}

                    
                    </React.Fragment>
                )}
            </div>

            <div className="nsModal-NextButton">
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </div>

        </div >
    );
    return (
        <Modal
            open={props.newStudyModalOpen}
            onClose={handleNewStudyClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}


export default NewStudyModal;
