import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import TitleBar from "../../components/common/TitleBar";
import { getSignedInUserFullName, isUserADoctor } from "../../auth/auth";
import AccountIcon from "../../icons/AccountIcon.png";

import "./SettingsPage.css";
import YourPlan from "./YourPlan";

function SettingsPage() {
  const [fullName, setFullname] = useState("");
  const [userName, ] = useState("");
  const [selectedTab, ] = useState(0);
  const [edit, setEdit] = useState(false);
  const [isDoctor, setIsDoctor] = useState<boolean>(false);

  useEffect(() => {
    isUserADoctor().then((isDoctor) => setIsDoctor(isDoctor));
  }, []);

  useEffect(() => {
    const fullname = getSignedInUserFullName();
    setFullname(fullname);
  }, []);

  return (
    <div className="parentContainer settings-page">
      <div className="mainContainer gap-1">
        {/* TITLE */}

        <TitleBar title={"Account Settings"} subtitle={fullName} />

        {/* <div className="title-container sky-blue-bg h-[170px] rounded-[10px] relative flex flex-column gap-6 justify-start">
          <div className="absolute w-full h-[112px]">
            <TitleBar title={"Account Settings"} subtitle={fullName} />
          </div>
          <div className="flex flex-row h-full items-start">
            <span
              onClick={() => {
                setSelectedTab(0);
              }}
              className={`cursor-pointer rounded-[10px] inline-flex h-full items-end w-[240px] text-center pb-3 justify-center font-bold
              ${selectedTab === 0 ? " bg-cyan-100 tab-selected " : "text-white"}`}>
              ACCOUNT INFORMATION
            </span>
            <span
              onClick={() => {
                setSelectedTab(1);
              }}
              className={`cursor-pointer rounded-[10px] inline-flex h-full items-end w-[240px] text-center pb-3 justify-center font-bold
              ${selectedTab === 1 ? " bg-sky-200 tab-selected " : "text-white"}`}>
              PROJECT SETTINGS
            </span>
          </div>
        </div> */}
        {/* CONTENT */}
        <div>
          {selectedTab === 0 ? (
            <>
              <div className="account-info-container box-container flex gap-5 p-10">
                <div>
                  <img src={AccountIcon} alt="Account icon" />
                </div>
                <div className="flex flex-column w-full">
                  <h1 className="font-extrabold title-gray text-lg mb-2">
                    Account Detail
                  </h1>
                  <div className="h-8 w-full flex items-center gap-10">
                    <span className="text-blue font-bold inline-block w-16">
                      Name:
                    </span>
                    <span className="">{fullName}</span>
                  </div>
                  <div className="h-8 w-full flex items-center gap-10">
                    <span className="text-blue font-bold inline-block w-16">
                      Username:
                    </span>
                    <span className="">{userName}</span>
                    <button
                      onClick={() => setEdit(!edit)}
                      className="inline-block text-button h-10 flex align-center font-bold underline gap-2">
                      Edit
                    </button>
                  </div>
                  <div>
                    <span>
                      <a
                        onClick={() => setEdit(!edit)}
                        className="text-button h-10 flex align-center font-bold underline ml-[104px]"
                        href="https://computingreappliedb2c.b2clogin.com/ComputingReAppliedB2C.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ChangePassword&client_id=6824c6db-3eea-4d7b-ac40-89406f33e362&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportal.computingreapplied.com&scope=openid&response_type=id_token&prompt=login">
                        Change Password
                      </a>
                    </span>
                  </div>
                  <hr className="border-t-12 border-blue w-full mt-6 mb-6" />
                  <h1 className="font-extrabold title-gray text-lg mb-2">
                    Delete Account
                  </h1>
                  <div className="flex justify-between">
                    <span>
                      <div>Would you like to delete your account?</div>
                      <div>
                        Deleting your account will remove all data associated
                        with it.
                      </div>
                    </span>
                    <Button
                      variant="contained"
                      sx={{
                        "color": "#E06C00",
                        "border": "2px solid #E06C00",
                        "borderRadius": "10px",
                        "backgroundColor": "white",
                        "textTransform": "none",
                        "fontSize": "15px",
                        "height": "45px",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#E06C00",
                        },
                        "&.Mui-disabled": {
                          border: "2px solid #f9f9f9",
                        },
                      }}
                      onClick={() => {
                        alert("Coming soon!");
                      }}>
                      Delete Account
                    </Button>
                  </div>
                </div>
              </div>
              <YourPlan isDoctor={isDoctor} />
            </>
          ) : (
            <div className="project-settings-container">WIP</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
