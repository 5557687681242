import ActivityCard from "../../../../components/common/ActivityCard";
//import { ReactComponent as AddPatientIcon } from "../../../../icons/AddPatientFig.svg";
import "../../Dashboard.css";
import "../../../../overall.css";
import { ReactComponent as BulletPoint } from "../../../../img/bullet-point.svg";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faDownload } from "@fortawesome/free-solid-svg-icons";

function formatDate(date: Date): string {
  const options = { month: "long", day: "numeric" } as const;
  return date.toLocaleDateString(undefined, options);
}

function getCurrentWeekRange(): string {
  const currentDate = new Date();
  const firstDayOfWeek = new Date(
    currentDate.setDate(currentDate.getDate() - currentDate.getDay())
  );
  const lastDayOfWeek = new Date(
    currentDate.setDate(firstDayOfWeek.getDate() + 6)
  );

  const formattedStart = formatDate(firstDayOfWeek);
  const formattedEnd = formatDate(lastDayOfWeek);

  return `${formattedStart} - ${formattedEnd}`;
}

// TODO:
function PatientsOverviewCard(props: any) {
  //console.log(props)
  return (
    <div className="po-mainContainer box-container flex justify-between">
      <section className="w-1/2">
        <div className="row edit-row">
          <div className="card-title-text">Patients Overview</div>
        </div>
        <div className="body-text body-text-size mrb-12 font-bold">
          This Week: {getCurrentWeekRange()}
        </div>
        <div className="stats-container flex justify-between">
          <ActivityCard
            short={true}
            cardType={"Meals"}
            data={
              parseInt(
                props.studyInfo[props.studies[props.studyIndex]?.studyID]
                  ?.mealsWeeklyRecentlyLogged
              ) || 0
            }
            label={"Recently Logged"}></ActivityCard>
          <ActivityCard
            short={true}
            cardType={"Symptoms"}
            data={
              parseInt(
                props.studyInfo[props.studies[props.studyIndex]?.studyID]
                  ?.symptomsWeeklyRecentlyLogged
              ) || 0
            }
            label={"Recently Logged"}></ActivityCard>
          <div className="invitationInfo"></div>
        </div>
      </section>
      <section className="w-1/2 flex justify-end">
        <div className="flex flex-column justify-between pr-10">
          <button
            onClick={props.openViewAllPatients}
            className="text-button h-10 flex align-center font-bold underline gap-2">
            View All Patients <BulletPoint />
          </button>
          <div className="flex flex-column justify-between h-[65%]">
            <h2 className="text-sm font-bold text-blue-900 ">
              Invite Code:{" "}
              {
                props.studyInfo[props.studies[props.studyIndex]?.studyID]
                  ?.studyEnrollmentCode
              }
            </h2>
            <Button
              onClick={() => {
                props.openInviteModal();
              }}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faUserPlus} />}
              sx={{
                color: "white",
                background: "#5FBDD6",
                borderRadius: "10px",
                textTransform: "none",
                width: "fit-content",
                padding: "5px 20px",
              }}>
              Invite Patient
            </Button>
            <Button
              onClick={() => {}}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faDownload} />}
              sx={{
                color: "white",
                background: "#5FBDD6",
                borderRadius: "10px",
                textTransform: "none",
                width: "fit-content",
                padding: "5px 20px",
              }}>
              Download all data
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PatientsOverviewCard;
