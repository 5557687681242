import { useEffect, useMemo, useState } from "react";
// import { useState,  } from 'react';
import StudyInformationCard from "./components/StudyDash/StudyInformationCard";
import PatientsOverviewCard from "./components/StudyDash/PatientsOverviewCard";
import StudyGraphComponent from "./components/StudyDash/StudyGraphComponent";
import ViewPatientsModal from "./components/Modals/ViewPatientsModal";

// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

import "./StudyDashboard.css";
import "../../overall.css";
import TitleBar from "../../components/common/TitleBar";
import DashBoardDropDown from "./DashBoardDropDown";
import InvitePatientModal from "../../components/invitePatientModal/InvitePatientModal";
// import GraphDisplay from "./GraphDisplay"

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

interface Study {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<Patient>;
  };
  studyID: Number;
}

type StudyDashProps = {
  patients: Array<Patient>;
  allStudyData: Study[];
  dashBoardStudyData: any;
};

type Patient = {
  $id: string;
  givenName: string;
  surname: string;
  userName: string;
  patientID: number;
};

// TODO:
function StudyDashboard(data: StudyDashProps) {
  const [viewPatientsModalOpen, setViewPatientsModalOpen] = useState(false);
  const [study, setStudy] = useState<Study>(data.allStudyData[0]);
  // const [patientsEnrolled, setPatientsEnrolled] = React.useState(0);
  const [studyIndex, setStudyIndex] = useState<number>(0);
  const [showInviteModal, setShowInviteModal] = useState(false);

  // useEffect(() => {
  //     });
  //   }, []);
  // Set up the states for visualization readd variables to state (had to supress warnings for deployment)
  useEffect(() => {
    // console.log(data);
    // console.log(data);
  }, [data, study]);

  const dropdown = useMemo(
    () => (
      <DashBoardDropDown
        setStudyIndex={setStudyIndex}
        setStudy={setStudy}
        study={study}
        studyIndex={studyIndex}
        studies={data.allStudyData}
      />
    ),
    [study, studyIndex, data.allStudyData]
  );

  return (
    <div className="parentContainer study-dashboard">
      <div className="mainContainer">
        {" "}
        {/* Rule in app.css */}
        {/*Send props to change title bar dependnig on page */}
        <TitleBar title={"Study Dashboard"} studyDropdown={dropdown} />
        {/*Send props to change title bar dependnig on page */}
        <div>
          <div className="studyInformation row">
            <StudyInformationCard
              studyIndex={studyIndex}
              studies={data.allStudyData}
              setViewPatientsModalOpen={setViewPatientsModalOpen}
              studyInfo={data.dashBoardStudyData.studies}
              studyId={study?.studyID}
            />
            <PatientsOverviewCard
              studyIndex={studyIndex}
              studies={data.allStudyData}
              studyInfo={data.dashBoardStudyData.studies}
              studyId={study?.studyID}
              openViewAllPatients={() => setViewPatientsModalOpen(true)}
              openInviteModal={() => setShowInviteModal(true)}
            />
          </div>
          <StudyGraphComponent studyId={study?.studyID} />
        </div>
      </div>
      <ViewPatientsModal
        onClose={() => setViewPatientsModalOpen(false)}
        show={viewPatientsModalOpen}
        study={data.allStudyData[studyIndex as number]}
        setShowInviteModal={() => setShowInviteModal(true)}
      />

      <InvitePatientModal
        show={showInviteModal}
        studies={data.allStudyData}
        studyData={data.dashBoardStudyData.studies}
        onClose={() => setShowInviteModal(false)}
      />
    </div>
  );
}
export default StudyDashboard;
