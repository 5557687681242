import React from "react";
import TitleBar from "../../components/common/TitleBar";
import PatientTable from "./PatientTable";
// import FilterSortByDropDown from './PatientDisplays/FilterSortByDropDown';
// import FilterStatusDropDown from './PatientDisplays/FilterStatusDropDown';
// import PatientDirectoryToggle from './PatientDisplays/PatientDirectoryToggle';

// import PatientCard from './PatientDisplays/PatientCard';
import "./PatientDirectory.css";

type DirectoryProps = {
  patients: Array<any>;
};

// TODO:
function PatientDirectory(patientData: DirectoryProps) {
  //suppressing warnings
  // const [, setDisplay] = useState("list")     //const [display, setDisplay] = useState("list")
  //const [, setStatus] = useState("")     //const [status, setStatus] = useState("")
  //const [, setSortBy] = useState("")     //const [sortBy, setSortBy] = useState("")
  // const onChangeDisplay = (str: string) => {
  //     setDisplay(str);
  // };
  // useEffect(() => {
  //     });
  //   }, []);
  return (
    <div className="mainContainer patientDirectory">
      <TitleBar title="Patient Directory" />
      <div className="patientTableContainer">
        <PatientTable data={patientData.patients} />
      </div>
    </div>
  );
}

export default PatientDirectory;
