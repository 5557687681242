import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import LoadingIcon from "../../../../components/common/LoadingIcon";
import { roundDecimal } from "../../../../utils/helperFunctions";

type TableProps = {
  data: any[];
  columns: any[];
};

function MicronutrientTable({ data, columns }: TableProps) {
  const [tableRows, setTableRows] = useState<Array<Object>>();
  const [dataLoaded] = useState<Boolean>(true);

  useEffect(() => {
    if (data.length === 0) {
      setTableRows([]);
      return;
    }
    const columnNames = Object.keys(data[0]);
    var totals: Array<Object> = [];

    data.forEach((value, index) => {
      let newValue: { [key: string]: any } = {};

      columnNames.forEach((columnName: string) => {
        if (columnName === "$id") {
          newValue["id"] = value[columnName];
        } else if (columnName === "consumedDate") {
          newValue[columnName] = value.consumedDate.split("T")[0];
        } else if (columnName === "userId") {
          newValue[columnName] = value[columnName];
          return;
        } else {
          newValue[columnName] = roundDecimal(
            value[columnName] === null ? 0 : value[columnName]
          );
        }
      });

      totals.push(newValue);
    });

    setTableRows(totals);
  }, [data]);

  if (tableRows && dataLoaded) {
    return (
      <DataGrid
        rows={tableRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
      />
    );
  } else {
    return (
      <div className="patientTableLoadingSpinner">
        <LoadingIcon />
        <h2 style={{ textAlign: "center", marginRight: "37.5px" }}>
          One moment, retrieving data...
        </h2>
      </div>
    );
  }
}

export default MicronutrientTable;
