import {
  UserAgentApplication,
  ClientAuthError,
  InteractionRequiredAuthError,
  Account,
} from "msal";
// import { b2cPolicies } from "./policies";
import { b2cPolicies, dev_b2cPolicies } from "./policies";
import { logoutRedirectUri, redirectUri } from "../utils/globalConstants";
import { initFetchRequest } from "../utils/helperFunctions";
import {
  API_PREFIX,
  API_Scope_Domain,
  MSALClientID,
  API_APP_ID_URI_Identifier,
  isDevelopmentEnvironment,
} from "../utils/globalConstants";

import React from "react";

type CacheLocation = any;

type msalConfigObject = {
  auth: {
    clientId: string;
    authority: string;
    validateAuthority: boolean;
    redirectUri: string;
    postLogoutRedirectUri: string;
  };
  cache: {
    cacheLocation: CacheLocation | undefined;
    storeAuthStateInCookie: boolean;
  };
};

export const isDevelopment = isDevelopmentEnvironment; // If in development environment

let appConfig;
let msalConfig: msalConfigObject;

// The current application coordinates were pre-registered in a B2C tenant.
appConfig = {
  b2cScopes: [
    API_Scope_Domain + "/" + API_APP_ID_URI_Identifier + "/Bella.Read",
  ],

  webApi: API_PREFIX + "/api/SymptomStudy/filter/dashboard",
};

// Reference: https://github.com/syncweek-react-aad/react-aad/issues/95#issuecomment-513178409
// TODO: Potentially come back and change this later.
const cacheLocation: "localStorage" = "localStorage";

if (isDevelopment) {
  /**********************DEVELOPMENTSERVER MSALCONFIG********************************* */

  // Configuration to initialize MSAL
  msalConfig = {
    auth: {
      clientId: MSALClientID,
      authority: dev_b2cPolicies.authorities.signUpSignIn.authority,
      validateAuthority: false,
      redirectUri: redirectUri,
      postLogoutRedirectUri: logoutRedirectUri,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie: true,
    },
  };
} else {
  /**********************PRODUCTION MSALCONFIG********************************* */
  // Configuration to initialize MSAL
  msalConfig = {
    auth: {
      clientId: MSALClientID, // This is your client ID
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      validateAuthority: false,
      redirectUri: redirectUri,
      postLogoutRedirectUri: logoutRedirectUri,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie: true,
    },
  };
}

// Request to signin, returns an idToken
const loginRequest = {
  scopes: appConfig.b2cScopes,
};

// Request to acquire a token for resource access
const tokenRequest = {
  scopes: appConfig.b2cScopes,
};

let msalObj: UserAgentApplication;

/**
 * Initializes a new UserAgentApplication.
 */
export const initNewMsalObject = async (isChangingPassword: Boolean) => {
  // if local storage has returned the user has tried changing password
  if (isChangingPassword) {
    if (isDevelopment) {
      msalConfig.auth.authority =
        dev_b2cPolicies.authorities.changePassword.authority;
    } else {
      msalConfig.auth.authority =
        b2cPolicies.authorities.changePassword.authority;
    }

    msalObj = new UserAgentApplication(msalConfig);
    try {
      msalObj.loginRedirect(loginRequest);
      window.localStorage.clear();
    } catch (err) {
      console.error(err);
      alert(err);
    }
  } else {
    msalObj = new UserAgentApplication(msalConfig);
  }
};

/**
 * Authenticates users using MSAL then retrieves and stores access token. Generates error
 * message at top right if authentication fails.
 *
 */
export const signIn = async () => {
  try {
    msalObj.loginRedirect(loginRequest);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Gets the account information of the current authenticated user.
 */
export const getAccount = (): Account | null => {
  return msalObj.getAccount();
};

// gets the Azure AD username of the current authenticated account
export const getUserName = (): string => {
  //console.log(msalObj.getAccount(), "GetACcount");
  //console.log(msalObj.getAccount().userName, "getUserName");
  return msalObj.getAccount().userName;
};

// gets the display username of the current authenticated account
export const getSignedInUserUsername = (): string => {
  //console.log(msalObj.getAccount(), "GetACcount");
  //console.log(msalObj.getAccount().idTokenClaims.extension_Username, "getUserName");
  if (
    msalObj.getAccount().name === undefined ||
    msalObj.getAccount().name === null ||
    msalObj.getAccount().name === "unknown"
  ) {
    return msalObj.getAccount().idTokenClaims.extension_Username;
  } else {
    return msalObj.getAccount().name;
  }
};

// gets the display name of the current authenticated account
export const getSignedInUserFullName = (): string => {
  return (
    msalObj.getAccount().idTokenClaims.given_name +
    " " +
    msalObj.getAccount().idTokenClaims.family_name
  );
};

// gets the display name of the current authenticated account
export const getSignedInUserFirstName = (): string => {
  return msalObj.getAccount().idTokenClaims.given_name;
};
// gets the display name of the current authenticated account
export const getSignedInUserLastName = (): string => {
  return msalObj.getAccount().idTokenClaims.family_name;
};

// gets the accountIdentifier of the current authenticated account
export const getAccountID = (): string => {
  return msalObj.getAccount().accountIdentifier;
};

// determines whether the user has an active session
export const isUserSignedIn = (): boolean => {
  return msalObj.getAccount() != null;
};

// Triggers user sign out.
export const signOut = () => {
  try {
    // Clears isDoctor and other auth information
    localStorage.clear();
    msalObj.logout();
  } catch (err) {
    console.error(err);
  }
};

/**
 * Attemps to acquire a user access token silently.
 *
 * @return {AuthResponse} The token.
 */
export const getToken = async () => {
  try {
    const token = await msalObj.acquireTokenSilent(tokenRequest);
    //console.log(token)
    // alert(token.accessToken)
    // localStorage.setItem("testKey", token.accessToken)
    return token;
  } catch (err) {
    console.error(err);

    // If the user is not signed in, sign them in.
    // If the user is signed in and acquireTokenSilent still fails,
    // force a popup to reauthenticate.
    if (err instanceof ClientAuthError) {
      signIn();
    } else if (err instanceof InteractionRequiredAuthError) {
      // idk how this actually works but...
      // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript
      msalObj.acquireTokenRedirect(tokenRequest);
    }
  }
};

export const getAccessToken = async () => {
  try {
    const token = await msalObj.acquireTokenSilent(tokenRequest);
    return token.accessToken;
  } catch (err) {
    console.error(err);
    if (err instanceof ClientAuthError) {
      signIn();
    } else if (err instanceof InteractionRequiredAuthError) {
      msalObj.acquireTokenRedirect(tokenRequest);
    }
  }
};

/**
 * Checks to see if the user is a doctor in the database.
 *
 * @return {Promise<boolean>} A promise containing whether the user is a doctor or not.
 */
export const isUserADoctor = async (): Promise<boolean> => {
  try {
    const accessToken = await getAccessToken();
    const request = initFetchRequest("/api/FoodStudy/isDoctor", accessToken);
    // console.log(accessToken)
    const response = await fetch(request);
    const isDoctor: boolean = await response.json();
    return isDoctor;
  } catch (err) {
    return false;
  }
};

/**
 * Gets the Signed user ID from the database.
 *
 * @return {Promise<number | null>} A promise containing the user ID.
 */
export const getSignedInUserId = async (): Promise<number | null> => {
  try {
    const accessToken = await getAccessToken();
    const request = initFetchRequest("/Users/GetSignedInUserId", accessToken);
    const response = await fetch(request);
    const userId: number = await response.json();
    return userId;
  } catch (err) {
    console.error("Cannot get user ID: " + err);
    // Handle errors
    return null; // return -1 or any invalid user ID when an error occurs
  }
};

export const AuthContext = React.createContext({
  authID: "",
  userID: "",
});

export const PatientContext = React.createContext({
  patientAuthID: "",
  patientID: "",
});
