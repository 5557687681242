import FormControl from "@mui/material/FormControl";
import { Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

interface Data {
  name: string;
  value: string;
}

type Props = {
  onChangeFunction: (e: any) => void;
  value: any;
  data: Data[];
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiSelect-select": {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "5px 14px",
    height: "fit-content",
    fontSize: 12,
  },
}));

function DropDown({ data, value, onChangeFunction }: Props) {
  return (
    <div className={`study-drop-down w-full`}>
      <FormControl sx={{ width: "100%" }}>
        <Select
          value={value}
          onChange={onChangeFunction}
          input={<BootstrapInput />}>
          {data.map((x) => {
            return (
              <MenuItem sx={{ fontSize: 12 }} value={x.value as string}>
                {x.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDown;
