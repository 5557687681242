import { ResponsiveBar } from "@nivo/bar";
import { useState, useEffect } from "react";

const MacroGraph = ({ data, macroChecks }) => {
  console.log(macroChecks);
  const [keys, setKeys] = useState(["protein, carbohydrates, fats, calories"]);

  useEffect(() => {
    if (macroChecks) {
      var appendKeys = [];
      if (macroChecks.proteinsChecked) {
        appendKeys.push("protein");
      }
      if (macroChecks.carbsChecked) {
        appendKeys.push("carbohydrates");
      }
      if (macroChecks.fatsChecked) {
        appendKeys.push("fats");
      }
      if (macroChecks.caloriesChecked) {
        appendKeys.push("calories");
      }
      setKeys(appendKeys);
    }
  }, [macroChecks]);

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="age/gender"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      valueFormat={{ format: "", enabled: false }}
      colors={{ scheme: "nivo" }}
      groupMode={"grouped"}
      defs={
        [
          // {
          //     id: 'dots',
          //     type: 'patternDots',
          //     background: 'inherit',
          //     color: '#38bcb2',
          //     size: 4,
          //     padding: 1,
          //     stagger: true
          // },
          // {
          //     id: 'lines',
          //     type: 'patternLines',
          //     background: 'inherit',
          //     color: '#eed312',
          //     rotation: -45,
          //     lineWidth: 6,
          //     spacing: 10
          // }
        ]
      }
      fill={
        [
          // {
          //     match: {
          //         id: 'fries'
          //     },
          //     id: 'dots'
          // },
          // {
          //     match: {
          //         id: 'sandwich'
          //     },
          //     id: 'lines'
          // }
        ]
      }
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Age/Gender",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Average Value (% RDA)",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default MacroGraph;
