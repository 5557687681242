import { createTheme } from '@mui/material';

declare module '@mui/material' {
    interface Theme {
      status: {
        danger: React.CSSProperties['color'];
      };
    }
  
    interface Palette {
      neutral: Palette['primary'];
    }
    interface PaletteOptions {
      neutral: PaletteOptions['primary'];
    }
  
    interface PaletteColor {
      darker?: string;
    }
    interface SimplePaletteColorOptions {
      darker?: string;
    }
    interface ThemeOptions {
      status: {
        danger: React.CSSProperties['color'];
      };
    }
  }
  
  export const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#5FBDD6',
        darker: '#428495',
        light: '#7fcade'
      },
      secondary: {
        main: '#E3AD84',
        darker: '#9e795c',
        light: '#e8bd9c'
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
      error: {
          main: '#E53E3E'
      }
    },
  });